import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import html2pdf from "html2pdf.js";
import {
  formatDateToDDMMYYYY,
  getAuthToken,
  getClientIdOfUser,
  showToast,
} from "../../utils/utility";
import { logoutFun } from "../HomeSideNavBar";
import * as Constants from "../../utils/Constants";
import MapLink from "../../utils/MapLink";
import { CardList } from "../CardView";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faMapLocationDot,
  faStarOfLife,
} from "@fortawesome/free-solid-svg-icons";
import "../TaskManagement/taskReport.css";
import ExportTrackingReport from "./ExportTrackingReport";

function TrackingReport() {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [reports, setReports] = useState({
    geofenceEvents: [],
    unassociatedLockUnlockReports: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [assetList, setAssetList] = useState([]);
  const [checkedItems, setCheckedItems] = useState({});

  // Define the reference
  const trackingReportRef = useRef(null);

  useEffect(() => {
    fetchData()
      .then((data) => {
        if (Array.isArray(data)) {
          setAssetList(data);
        } else {
          console.error("Data is not an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const fetchData = async () => {
    try {
      const endPoint = `${Constants.API_V1}/${getClientIdOfUser()}/${
        Constants.EP_ASSETS_GET_ALL
      }`;
      axios.defaults.headers.common["Authorization"] = `${
        Constants.BEARER
      } ${getAuthToken()}`;
      const response = await axios.get(`${Constants.BASE_URL}${endPoint}`);
      if (response.status === 200) {
        return response.data.responseData;
      } else if (response.status === 403) {
        logoutFun();
      } else {
        console.error(`Error: ${response.status} - ${response.statusText}`);
        return null;
      }
    } catch (error) {
      console.error(error);
      logoutFun();
      return null;
    }
  };

  const handleSearch = async () => {
    setIsLoading(true);
    setError(null);

    try {
      // Validate inputs
      if (!validateInputs(checkedItems, startDate, endDate)) {
        return; // Stop execution if validation fails
      }

      axios.defaults.headers.common["Authorization"] = `${
        Constants.BEARER
      } ${getAuthToken()}`;

      let apiUrl = `api/v1/${getClientIdOfUser()}/tracking-report`;

      const checkedAssetIds = Object.keys(checkedItems).filter(
        (assetId) => checkedItems[assetId]
      );

      if (checkedAssetIds.length > 0) {
        const assetIdsQueryParam = checkedAssetIds.join(",");
        apiUrl += `?assetId=${assetIdsQueryParam}`;
      }

      apiUrl += `&startDate=${startDate} 00:00:00&endDate=${endDate} 23:59:59`;

      const response = await axios.get(Constants.BASE_URL + apiUrl);

      if (response.status === 200) {
        const responseData = response.data;
        setReports({
          geofenceEvents: responseData.responseData.reports,
          unassociatedLockUnlockReports:
            responseData.responseData.unassociatedLockUnlockReports,
        });
      } else if (response.status === 403) {
        logoutFun();
      } else {
        console.error(response.status);
      }
    } catch (error) {
      console.error(error.message);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  // Validation helper function
  function validateInputs(checkedItems, startDate, endDate) {
    // Check if mandatory fields are empty
    if (Object.keys(checkedItems).length === 0 || !startDate || !endDate) {
      const errorMessage =
        "Please select mandatory fields (*) [Device, Start Date & End Date] to generate the report.";
      alert(errorMessage);
      return false;
    }

    // Check if startDate is later than endDate
    if (parseDate(startDate) > parseDate(endDate)) {
      const dateErrorMessage =
        "Start Date should be earlier than or equal to the End Date.";
      alert(dateErrorMessage);
      return false;
    }

    return true;
  }

  // Helper function to parse DD/MM/YYYY date strings
  function parseDate(dateString) {
    const [day, month, year] = dateString.split("/").map(Number);
    return new Date(year, month - 1, day); // Month is 0-indexed
  }

  const handleCheckboxToggle = (assetUnitId) => {
    setCheckedItems((prev) => ({
      ...prev,
      [assetUnitId]: !prev[assetUnitId],
    }));
  };

  const updateAssetNameInReport = (updatedReports) => {
    return updatedReports.map((report) => {
      const assetId = report.deviceId;
      const assetName = getAssetNameByAssetUnitId(assetId);
      return { ...report, assetName: assetName };
    });
  };

  const getAssetNameByAssetUnitId = (targetAssetUnitId) => {
    const targetAsset = assetList.find(
      (asset) => asset.assetId === targetAssetUnitId
    );
    return targetAsset ? targetAsset.name : null;
  };

  const handleClear = () => {
    setStartDate("");
    setEndDate("");
    setReports({ geofenceEvents: [], unassociatedLockUnlockReports: [] });
    setCheckedItems({});

    const elements = document.getElementsByClassName("input-field");
    for (let i = 0; i < elements.length; i++) {
      elements[i].value = "";
    }
  };

  const handleExportPDF = () => {
    if (
      reports.geofenceEvents.length === 0 &&
      reports.unassociatedLockUnlockReports.length === 0
    ) {
      showToast("No data available for export.", {
        autoClose: 3000,
        type: "error",
        position: "top-center",
      });
      return;
    }

    const element = trackingReportRef.current;
    const now = new Date();
    const istOffset = 330; // IST is UTC +5:30, which is 330 minutes ahead
    const istTime = new Date(now.getTime() + istOffset * 60 * 1000);
    const formattedDate = istTime
      .toISOString()
      .slice(0, 19)
      .replace(/[:T]/g, "-");
    const fileName = `TrackingReport_${formattedDate}.pdf`;

    html2pdf(element, {
      margin: [0.5, 0.5], // Adjust margins (in inches)
      filename: fileName,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    });
  };

  const renderTrackingTable = () => {
    return (
      <div>
        {reports.geofenceEvents.map((report, reportIndex) => (
          <div key={reportIndex} className="tracking-report">
            <div className="section-title">
              <FontAwesomeIcon icon={faMapLocationDot} /> Location -{" "}
              {reportIndex + 1}
            </div>
            <table style={{ width: "100%" }} className="tracking-report-table">
              <tbody>{renderTrackingRow(report)}</tbody>
            </table>
            <br />
          </div>
        ))}

        {reports.unassociatedLockUnlockReports.length > 0 && (
          <div className="tracking-report">
            <h4 className="section-title">
              <FontAwesomeIcon icon={faStarOfLife} /> Unassociated Lock/Unlock
              Events
            </h4>
            <table className="tracking-report-table" style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th>Report Id</th>
                  <th>Event Source</th>
                  <th>Time</th>
                  <th>Location Link</th>
                </tr>
              </thead>
              <tbody>
                {reports.unassociatedLockUnlockReports.map((event, index) => (
                  <tr key={index}>
                    <td>{event.reportSerialNumber}</td>
                    <td>{event.eventSource}</td>
                    <td>
                      {new Date(event.reportDateTime).toLocaleString("en-IN", {
                        timeZone: "UTC",
                      })}
                    </td>
                    <td>
                      <MapLink
                        latitude={event.latitude}
                        longitude={event.longitude}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    );
  };

  const renderTrackingRow = (tracking) => {
    return (
      <React.Fragment key={tracking.customerId}>
        <tr className="sub-title-header-1">
          <td colSpan={8} className="tracking-header">
            Customer Details
          </td>
        </tr>
        <tr>
          <td colSpan={1}>
            <strong>Customer Id:</strong> {tracking.customerId}
          </td>
          <td colSpan={3}>
            <strong>Customer Name:</strong> {tracking.customerName}
          </td>

          <td colSpan={4}>
            <strong>Address:</strong> {tracking.customerAddress}
          </td>
        </tr>
        <tr>
          <td>
            <b>Email Id:</b>
          </td>{" "}
          <td colSpan={3}>{tracking.email}</td>
          <td>
            <b>Phone Number:</b>
          </td>
          <td colSpan={2}>{tracking.phoneNumber}</td>
        </tr>
        <tr>
          <td colSpan={8}>
            <strong>Location Link:</strong>{" "}
            <MapLink
              latitude={tracking.latitude}
              longitude={tracking.longitude}
            />
          </td>
        </tr>
        <tr className="sub-title-header-1">
          <td colSpan={8} className="tracking-header title-p">
            Device Activity
          </td>
        </tr>
        <tr>
          <td className="stretch-width" colSpan={5}>
            <table style={{ width: "100%" }}>
              <tr>
                <th>Device Id</th>
                <th>Event Type</th>
                <th>Time</th>
              </tr>
              {tracking.geofenceEvents.map((event, index) => (
                <tr key={index}>
                  <td>{event.assetId}</td>
                  <td>
                    <strong>{event.eventType}</strong>
                  </td>
                  <td colSpan={3}>
                    {new Date(event.timestamp).toLocaleString("en-IN", {
                      timeZone: "UTC",
                    })}
                  </td>
                </tr>
              ))}
            </table>
          </td>
          <td colSpan={2} style={{ alignContent: "baseline" }}>
            <table style={{ width: "100%", alignSelf: "initial" }}>
              <tr>
                {" "}
                <th colSpan={3}>Lock and Unlock Events </th>
              </tr>
              {tracking.lockUnlockEvents.map((lockUnlockEvent, eventIndex) => (
                <React.Fragment key={eventIndex}>
                  <tr key={eventIndex}>
                    <td rowSpan={2}>
                      <strong>ReportId:</strong>{" "}
                      {lockUnlockEvent.reportSerialNumber} <br />
                      <MapLink
                        latitude={lockUnlockEvent.latitude}
                        longitude={lockUnlockEvent.longitude}
                      />
                    </td>
                    <td>
                      <b>Event Source:</b>{" "}
                    </td>
                    <td>{lockUnlockEvent.eventSource}</td>
                  </tr>

                  <tr>
                    <td>
                      <b>Time</b>
                    </td>
                    <td>
                      {new Date(lockUnlockEvent.reportDateTime).toLocaleString(
                        "en-IN",
                        {
                          timeZone: "UTC",
                        }
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>DeviceId:</strong>{" "}
                    </td>
                    <td colSpan={2}>{lockUnlockEvent.assetId}</td>
                  </tr>
                </React.Fragment>
              ))}
            </table>
          </td>
        </tr>
      </React.Fragment>
    );
  };

  return (
    <div className="main-child">
      <div className="central-section container scrollable-y">
        {reports.geofenceEvents.length <= 0 && (
          <span>
            Your reports will appear here, you can select asset(s) and Date
            Range.
          </span>
        )}

        {isLoading && (
          <div className="progress-bar">
            <div className="progress">
              <FontAwesomeIcon icon={faClock} spin />
            </div>
          </div>
        )}
        {reports.geofenceEvents.length > 0 && (
          <div ref={trackingReportRef} id="#task-report">
            <div className="section-title">Tracking Report</div>
            <div className="task-list">{renderTrackingTable()}</div>
          </div>
        )}
      </div>

      <div className="search-container  scrollable-y">
        <div>
          <label>
            Devices:{" "}
            <FontAwesomeIcon
              icon={faStarOfLife}
              size="2xs"
              style={{ color: "#ea063f" }}
            />
          </label>
          <CardList
            data={assetList}
            checkedItems={checkedItems}
            setCheckedItems={setCheckedItems}
            toggleCheckbox={handleCheckboxToggle}
          />
        </div>

        <span>
          <lable>
            <u>Select Date range</u>
          </lable>
        </span>
        <div>
          <div>
            <label>
              Start Date:{" "}
              <FontAwesomeIcon
                icon={faStarOfLife}
                size="2xs"
                style={{ color: "#ea063f" }}
              />{" "}
            </label>
            <input
              className="search-child input-field"
              type="date"
              onChange={(e) =>
                setStartDate(formatDateToDDMMYYYY(e.target.value))
              }
            />
          </div>

          <div>
            <label>
              End Date:{" "}
              <FontAwesomeIcon
                icon={faStarOfLife}
                size="2xs"
                style={{ color: "#ea063f" }}
              />{" "}
            </label>
            <input
              type="date"
              className="search-child input-field"
              onChange={(e) => setEndDate(formatDateToDDMMYYYY(e.target.value))}
            />
          </div>
          <br />
          <div>
            <button className="search-child" onClick={handleSearch}>
              Fetch Report
            </button>
            <div className="button-container search-child">
              <button className="search-child" onClick={handleExportPDF}>
                Export to PDF
              </button>
              <ExportTrackingReport data={reports} />
            </div>
            <button className="search-child" onClick={handleClear}>
              Clear
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TrackingReport;
