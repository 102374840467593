import React from "react";
import axios from "axios";
import './login.css'
import full_icon from '../assets/Yekkada_256x72.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightToBracket } from "@fortawesome/free-solid-svg-icons";
import * as Constants from "../utils/Constants";

const Login = () => {
    const [username, setUserName] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [errorMsg, setErrorMsg] = React.useState("");

    const handleEnter = (event) => {
        if (event.keyCode === 13) {
            login()
        }
    };


    const login = async () => {
        const body = {
            username,
            password,
        };

        try {
            // axios.defaults.headers.delete('Authorization')
            const response = await axios.post(Constants.BASE_URL + 'api/auth/v1/authenticate', body);

            if (response.status === 200) {
                // The login was successful.
                // You can now store the token in local storage or session storage.

                const loggedInUser = response.data;
                console.log("User: " + loggedInUser)
                localStorage.setItem(Constants.LOGGED_IN_USER, JSON.stringify(loggedInUser));
                localStorage.setItem(Constants.IS_AUTHENTICATED, true)
                window.location.href = '/home';
            } else {
                // The login request failed.
                setErrorMsg("Authentication failed, Invalid credentials.")
                localStorage.removeItem('isAuthenticated')
            }
        } catch (error) {
            console.error(error)
            setErrorMsg("Authentication failed, Invalid credentials.")
            localStorage.removeItem(Constants.IS_AUTHENTICATED)
            localStorage.removeItem(Constants.LOGGED_IN_USER)
        }

    };

    return (
        <div className="login-container">
            <div className="login-page">
                <img src={full_icon} alt="" className="login-page-icon" />

                <input className="login-page-element"
                    type="text"
                    placeholder="Username"
                    value={username}
                    onChange={(event) => setUserName(event.target.value)}
                />

                <input className="login-page-element"
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                    onKeyDown={handleEnter}
                />

                <button className="login-page-element" onClick={login}>
                    <FontAwesomeIcon icon={faArrowRightToBracket} />
                    &nbsp; &nbsp;
                    <span>Login</span>
                </button>
                <span className="errMsg">{errorMsg}</span>
            </div>
        </div>
    );
};

export default Login;
