import React, { useState } from "react";
import HomeSideNavBar from "./HomeSideNavBar";
import Header from "./Header";
import "./home.css";
import ApiDataProvider from "./ApiDataContext";
import LockUnlockReportSearch from "./LockUnlockReportSearch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationPinLock,
  faFileExcel,
  faMagnifyingGlassChart,
} from "@fortawesome/free-solid-svg-icons";
import TaskReport from "../component/TaskManagement/TaskReport";
import TrackingReport from "./Customer/TrackingReport";

const LOCK_UNLOCK_REP = "LockUnlockReports";
const ASSET_REPORTS = "AssetReports";
const TASK_REPORT = "TaskReport";

function Reports() {
  const [selectedComponent, setSelectedComponent] = useState(LOCK_UNLOCK_REP);

  const renderSelectedComponent = () => {
    switch (selectedComponent) {
      case LOCK_UNLOCK_REP:
        return <LockUnlockReportSearch />;
      case TASK_REPORT:
        return <TaskReport />;
      case ASSET_REPORTS:
        return <TrackingReport />;
      default:
        return null;
    }
  };

  return (
    <ApiDataProvider>
      <div className="container">
        <div className="screen-layout">
          <Header />
          <HomeSideNavBar />
          <div className="main-section-div">
            <div className="report-nav-bar">
              <button
                className={
                  selectedComponent === LOCK_UNLOCK_REP ? "selected" : ""
                }
                onClick={() => setSelectedComponent(LOCK_UNLOCK_REP)}
              >
                <FontAwesomeIcon
                  style={{ color: "#363168" }}
                  icon={faLocationPinLock}
                />{" "}
                &nbsp;Lock Unlock Reports
              </button>
              <button
                className={selectedComponent === TASK_REPORT ? "selected" : ""}
                onClick={() => setSelectedComponent(TASK_REPORT)}
              >
                <FontAwesomeIcon
                  style={{ color: "#363168" }}
                  icon={faFileExcel}
                />{" "}
                &nbsp;Task Report
              </button>
              <button
                className={
                  selectedComponent === ASSET_REPORTS ? "selected" : ""
                }
                onClick={() => setSelectedComponent(ASSET_REPORTS)}
              >
                <FontAwesomeIcon
                  style={{ color: "#363168" }}
                  icon={faMagnifyingGlassChart}
                />{" "}
                &nbsp;Asset Tracking Reports
              </button>
            </div>
            {renderSelectedComponent()}
          </div>
        </div>
      </div>
    </ApiDataProvider>
  );
}

export default Reports;
