import React from "react";
import './../cardView.css';

export const TaskCardView = ({ taskId, deviceId, taskTitle, isChecked, toggleCheckbox }) => {
    const handleCheckboxChange = () => {
        toggleCheckbox(taskId);
    };

    return (
        <div className="card" onClick={handleCheckboxChange}>
            <div>
                <input
                    type="checkbox"
                    checked={isChecked}
                />
            </div>
            <div>
                <div className="primary-id">Task Id: {taskId}</div>
                <div className="secondary-id">Device Id: {deviceId}</div>
                <div className="secondary-id">Task Title: {taskTitle}</div>
            </div>
        </div>
    );
};

export const TaskCardList = ({ data, checkedItems, toggleCheckbox }) => {
    return (
        <div className="card-list">
            {data && data.map((item) => (
                <TaskCardView
                    key={item.taskId}
                    taskId={item.taskId}
                    deviceId={item.deviceId}
                    taskTitle={item.title}
                    isChecked={!!checkedItems[item.taskId]}
                    toggleCheckbox={toggleCheckbox}
                />
            ))}
        </div>
    );
};
