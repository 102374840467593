import React, { createContext, useState } from "react";
import axios from "axios";
import * as Constants from "../utils/Constants";
import {getAuthToken} from "../utils/utility";

export const ApiDataContext = createContext({
  data: null,
  componentToRender: Constants.COMP_DASHBOARD
});


const ApiDataProvider = ({ children }) => {
  const [data, setData] = useState(null);
  const [componentToRender, setComponentToRender] = useState("");

  const fetchData = async (endPoint) => {
    try {
      console.log("API end point: " + Constants + endPoint)
      axios.defaults.headers.common['Authorization'] = `${Constants.BEARER} ${getAuthToken()}`;
      const response = await axios.get(Constants.BASE_URL + endPoint);
      if (response.status === 200) {
        const responseData = response.data;
        setData(responseData);
      } else {
        window.location.href = "/" + Constants.LOGIN;
        localStorage.removeItem(Constants.LOGGED_IN_USER)
        localStorage.removeItem(Constants.IS_AUTHENTICATED)
      }
    } catch (error) {
      window.location.href = "/" + Constants.LOGIN;
      localStorage.removeItem(Constants.LOGGED_IN_USER)
      localStorage.removeItem(Constants.IS_AUTHENTICATED)
    }
  };

  return (
    <ApiDataContext.Provider value={{ data, fetchData, componentToRender, setComponentToRender }}>
      {children}
    </ApiDataContext.Provider>
  );
};

export default ApiDataProvider;