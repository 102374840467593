import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { customStyles } from "../../utils/DataTableCustomStyle";
import * as UrlConstants from "../../utils/UrlConstants";
import * as Constants from "../../utils/Constants";
import axios from "axios";
import { logoutFun } from "../HomeSideNavBar";
import {
  getAuthToken,
  getClientIdOfUser,
  showToast,
} from "../../utils/utility";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";

import { Tooltip } from "react-tooltip";

export default function ListAssets() {
  const [assets, setAssets] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const columns = [
    {
      name: "Id",
      selector: (row) => row.assetId,
      sortable: true,
      grow: 0.35,
    },
    {
      name: "Asset Name",
      selector: (row) => row.name,
      sortable: true,
      wrap: true,
      grow: 0.5,
    },
    {
      name: "Asset Unit Id",
      selector: (row) => row.assetUnitId,
      sortable: true,
      grow: 0.5,
    },
    {
      name: "Asset Type",
      selector: (row) => row.assetType,
      sortable: true,
      grow: 0.8,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
      wrap: true,
    },
    {
      name: "Status",
      selector: (row) => (
        <select
          value={row.assetStatus}
          onChange={(e) =>
            confirmStatusChange(row.assetId, e.target.value, row.assetStatus)
          }
        >
          <option value="ACTIVE">Active</option>
          <option disabled="true" value="ASSIGNED">Assigned</option>
          <option value="TRACKED">Tracked</option>
          <option value="SUSPENDED">Suspended</option>
        </select>
      ),
      sortable: true,
      wrap: true,
      grow: 0.6,
    },
    {
      name: "Manage",
      selector: (row) => <AssetManageComponent assetId={row.assetId} />,
      sortable: true,
    },
  ];

  useEffect(() => {
    fetchData()
      .then((data) => {
        if (Array.isArray(data)) {
          setAssets(data);
          setIsLoading(false);
        } else {
          console.error("Data is not an array:", data);
          setError("No Data found");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setError("Failed to load data");
      });
  }, []);

  const fetchData = async () => {
    try {
      const endPoint = `${Constants.API_V1}/${getClientIdOfUser()}/assets/${
        UrlConstants.GET_ALL_ASSETS
      }`;
      console.log("API endpoint:", Constants.BASE_URL + endPoint);

      axios.defaults.headers.common["Authorization"] = `${
        Constants.BEARER
      } ${getAuthToken()}`;
      setIsLoading(true);
      const response = await axios.get(`${Constants.BASE_URL}${endPoint}`);

      if (response.status === 200) {
        const modifiedData = response.data.responseData.map((asset) => ({
          ...asset,
          edit: AssetManageComponent(asset.assetId),
        }));
        return modifiedData;
      } else if (response.status === 403) {
        logoutFun();
      } else {
        console.error(`Error: ${response.status} - ${response.statusText}`);
        return null;
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      return null;
    }
  };

  const confirmStatusChange = (assetId, newStatus, currentStatus) => {
    if (currentStatus === 'ASSIGNED') {
        alert("This asset may be assigned to a task, Its status can only be updated after task completion.");
        return;
    }

    if (newStatus !== currentStatus) {
        if (window.confirm(`Are you sure you want to change the status to ${newStatus}?`)) {
            updateAssetStatus(assetId, newStatus);
        }
    }
};


  const updateAssetStatus = async (assetId, newStatus) => {
    try {
        const endPoint = `${Constants.API_V1}/${getClientIdOfUser()}/assets`;
        console.log("API endpoint for updating status:", Constants.BASE_URL + endPoint);

        axios.defaults.headers.common['Authorization'] = `${Constants.BEARER} ${getAuthToken()}`;
        const response = await axios.put(`${Constants.BASE_URL}${endPoint}`, null, {
            params: {
                assetId: assetId,
                assetStatus: newStatus
            }
        });

        if (response.status === 200) {
            setAssets(prevAssets => prevAssets.map(asset => asset.assetId === assetId ? { ...asset, assetStatus: newStatus } : asset));
            showToast('Status updated successfully', { autoClose: 1500, type: 'success', position: 'top-center' });
        } else {
            console.error(`Error: ${response.status} - ${response.statusText}`);
            showToast('Failed to update status', { autoClose: 1500, type: 'error', position: 'top-center' });
        }
    } catch (error) {
        console.error("Error updating status:", error);
        showToast('Failed to update status', { autoClose: 1500, type: 'error', position: 'top-center' });
    }
};



  return (
    <div className="main-child">
      <div className="central-section container scrollable-y">
        <div className="section-title">
          <span>Asset List</span>
        </div>
        <br />
        <DataTable
          title=""
          columns={columns}
          data={assets}
          pagination
          responsive
          highlightOnHover
          striped
          progressPending={isLoading}
          noDataComponent={error ? <div>{error}</div> : null}
          keyField={(row) => row.assetId}
          customStyles={customStyles}
        />
        <Tooltip id="my-tooltip" />
      </div>
    </div>
  );
}

function AssetManageComponent({ assetId }) {
  return (
    <>
      <FontAwesomeIcon
        onClick={() => editAsset(assetId)}
        data-tooltip-id="my-tooltip"
        data-tooltip-content="Edit Asset"
        size="lg"
        icon={faPenToSquare}
      />
      &nbsp; &nbsp; &nbsp; &nbsp;
      <FontAwesomeIcon
        onClick={() => suspendUser(assetId)}
        data-tooltip-id="my-tooltip"
        data-tooltip-content="Suspend Asset"
        size="lg"
        icon={faCircleXmark}
      />
    </>
  );
}

function editAsset(assetId) {
  showToast("To be implemented", {
    autoClose: 1500,
    type: "info",
    position: "top-center",
  });
}

function suspendUser(assetId) {
  showToast("To be implemented", {
    autoClose: 1500,
    type: "info",
    position: "top-center",
  });
}
