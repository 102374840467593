import React, { useState } from "react";
import axios from "axios";
import * as XLSX from "xlsx";
import "../home.css";
import {
  generateRandomNumber,
  getLoggedInUserId,
  formatDateToDDMMYYYY,
  getAuthToken,
  getClientIdOfUser,
  showToast,
} from "../../utils/utility";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

import * as Constants from "../../utils/Constants";

const CustomerDetailsUpload = () => {
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      alert("Please select a file first!");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("requestId", generateRandomNumber());
    formData.append("userId", getLoggedInUserId());

    try {
      const response = await axios.post(
        `${Constants.BASE_URL}${
          Constants.API_V1
        }/${getClientIdOfUser()}/customers/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `${Constants.BEARER} ${getAuthToken()}` ,
          },
        }
      );
      console.log("Upload successful:", response.data);
      alert("Upload successful!");
    } catch (error) {
      console.error("Error uploading file:", error.response);
      alert("Error uploading file:: "+ error.response.data.responseMessage);
    }
  };

  return (
    <div className="main-child">
      <div className="central-section container scrollable-y">
        <div className="section-title">
          <span>Upload Customer List</span>
        </div>
        <br />
        <div className="description">
          <FontAwesomeIcon icon={faInfoCircle} className="info-icon" />
          <p>
            Use this function to upload customer details from an Excel sheet.
            The sheet should contain columns for Customer Name, Address,
            Latitude, Longitude, Email ID, and Phone Number.
          </p>
        </div>
        <div>
          <input className="yekkada-input" type="file" accept=".xlsx, .xls" onChange={handleFileChange} />
          <button className="yekkada-btn" onClick={handleUpload}>
            Upload
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomerDetailsUpload;
