import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';

const NotificationIcon = ({ onClick }) => {

  return (
    <div className='notification-div'>
      <span className="notification-icon" onClick={onClick}>
        <FontAwesomeIcon style={{ color: "#363168" }} icon={faBell} size='xl' />
      </span>
    </div>
  );
};

export default NotificationIcon;
