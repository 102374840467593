import React, {useState} from "react";
import HomeSideNavBar from "./HomeSideNavBar";
import Header from "./Header";
import "./home.css";
import ApiDataProvider from "./ApiDataContext";
import UnderDevelopment from "../utils/UnderDevelopment";
import ProfilePage from "./UserManagement/ProfilePage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faMicrochip } from "@fortawesome/free-solid-svg-icons";

function Profile() {
  const PROFILE = "PropfilePage";
  const CHANGE_PASSWORD = "ChangePassword";

  const [selectedComponent, setSelectedComponent] = useState(PROFILE);

  const renderSelectedComponent = () => {
    switch (selectedComponent) {
      case PROFILE:
        return <ProfilePage />;
      case CHANGE_PASSWORD:
        return <UnderDevelopment />;

      default:
        return null;
    }
  };

  return (
    <ApiDataProvider>
      <div className="container">
        <div className="screen-layout">
          <Header />
          <HomeSideNavBar />
          <div className="main-section-div">
            <div className="report-nav-bar">
              <button
                className={selectedComponent === PROFILE ? "selected" : ""}
                onClick={() => setSelectedComponent(PROFILE)}
              >
                <FontAwesomeIcon
                  style={{ color: "#363168" }}
                  icon={faMicrochip}
                />{" "}
                &nbsp;Your Details
              </button>
              <button
                className={
                  selectedComponent === CHANGE_PASSWORD ? "selected" : ""
                }
                onClick={() => setSelectedComponent(CHANGE_PASSWORD)}
              >
                <FontAwesomeIcon style={{ color: "#363168" }} icon={faFile} />{" "}
                &nbsp;Change Password
              </button>
            </div>
            {renderSelectedComponent()}
          </div>
        </div>
      </div>
    </ApiDataProvider>
  );
}

export default Profile;
