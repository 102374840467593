import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import "../home.css";
import {
  getAuthToken,
  getClientIdOfUser,
  showToast,
} from "../../utils/utility";
import { customStyles } from "../../utils/DataTableCustomStyle";
import MapLink from "../../utils/MapLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faPenToSquare,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";

import * as Constants from "../../utils/Constants";
import { Tooltip } from "react-tooltip";
import axios from "axios";

const CustomerList = () => {
  const [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const columns = [
    {
      name: "Customer ID",
      selector: (row) => row.customerId,
      sortable: true,
      grow: 0.25,
    },
    {
      name: "Customer Name",
      selector: (row) => row.customerName,
      sortable: true,
      grow: 0.5,
    },
    {
      name: "Address",
      selector: (row) => row.address,
      sortable: true,
      wrap: true,
      grow: 1,
    },

    {
      name: "Map Location",
      selector: (row) => (
        <MapLink latitude={row.latitude} longitude={row.longitude} />
      ),
      sortable: true,
      grow: 0.35,
    },
    {
      name: "Email ID",
      selector: (row) => row.emailId,
      sortable: true,
      grow: 0.5,
    },
    {
      name: "Phone Number",
      selector: (row) => row.phoneNumber,
      sortable: true,
      grow: 0.5,
    },
    {
      name: "Manage",
      selector: (row) => <CustomerManagement customerId={row.customerId} />,
      sortable: true,
      grow: 0.3,
    },
  ];

  useEffect(() => {
    fetchData()
      .then((data) => {
        if (Array.isArray(data)) {
          setCustomers(data);
          setIsLoading(false);
        } else {
          console.error("Data is not an array:", data);
          setError("No Data found");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setError("Error fetching data");
      });
  }, []);

  const fetchData = async () => {
    try {
      const endPoint = `${Constants.API_V1}/${getClientIdOfUser()}/customers`;
      console.log("API endpoint:", Constants.BASE_URL + endPoint);

      axios.defaults.headers.common["Authorization"] = `${
        Constants.BEARER
      } ${getAuthToken()}`;
      setIsLoading(true);
      const response = await axios.get(`${Constants.BASE_URL}${endPoint}`);

      if (response.status === 200) {
        return response.data.responseData;
      } else {
        console.error(`Error: ${response.status} - ${response.statusText}`);
        return null;
      }
    } catch (error) {
      console.error(error);
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  function CustomerManagement(customerId) {
    return (
      <>
        <FontAwesomeIcon
          onClick={() => editCustomer(customerId)}
          data-tooltip-id="my-tooltip"
          data-tooltip-content="Edit Customer"
          size="lg"
          icon={faPenToSquare}
        />
        &nbsp; &nbsp; &nbsp; &nbsp;
        <FontAwesomeIcon
          onClick={() => deleteCustomer(customerId)}
          data-tooltip-id="my-tooltip"
          data-tooltip-content="Delete Customer"
          size="lg"
          icon={faCircleXmark}
        />
      </>
    );
  }

  function editCustomer(customerId) {
    showToast("To be implemented", {
      autoClose: 1500,
      type: "info",
      position: "top-center",
    });
  }

  function deleteCustomer(customerId) {
    showToast("To be implemented", {
      autoClose: 1500,
      type: "info",
      position: "top-center",
    });
  }

  return (
    <div className="main-child">
      <div className="central-section container scrollable-y">
        <div className="section-title">
          <span>Customer List</span>
        </div>
        <br />
        <div className="description" style={{display:'none'}}>
          <FontAwesomeIcon icon={faInfoCircle} className="info-icon" />
          <p>
            List of customer for which smart lock visits are tracked without
            task creation.
          </p>
        </div>
        <div>
          <DataTable
            columns={columns}
            data={customers}
            pagination
            responsive
            highlightOnHover
            striped
            progressPending={isLoading}
            noDataComponent={error ? <div>{error}</div> : null}
            customStyles={customStyles}
          />
          <Tooltip id="my-tooltip" />
        </div>
      </div>
    </div>
  );
};

export default CustomerList;
