import React, {useEffect, useState} from "react";
import companyLogo from "../assets/yekkada_logo.png"

export default function Headers() {
    let [userName, setUserName] = useState('')

    useEffect(()=>{
        const loggedInUser = JSON.parse(localStorage.getItem('loggedInUser'))
        setUserName(loggedInUser.userName)
    },[])

    return (
        <div className="grid-div header">
            <span className="user-name-span">Welcome: {userName}</span>
            <img className="company-logo" src={companyLogo} alt="" />
        </div>
    )
}