export const LOCATION_ALERT_REPORTS = 'location-alert-reports';
export const GET_ALERTS_COUNT = "getAlertsCount";
export const GET_ALERTS = "getAlerts";
export const MARK_ALERTS_AS_READ = 'markAlertsAsRead';
export const GET_ALL_CLIENT_USERS = 'getAllClientUsers'
export const GET_ALL_ACTIVE_EMPLOYEES = 'getAllActiveEmployees';

export const GET_ALL_ASSETS = 'getAllAssets';
export const GET_ALL_AVAILABLE_ASSETS = 'getAllAvailableAssets';
export const USERS = 'users';
export const REGISTER_ASSETS = 'registerAssets';
export const ASSETS = 'assets';
export const CUSTOMERS = 'customers';

//Task End points
export const GET_ALL_TASKS_BY_STATUS = 'getAllTasksByStatus'