import React, { useEffect, useRef, useState } from "react";

const AddressSearch = ({ onPlaceSelected, latitude, longitude, address }) => {
  const inputRef = useRef(null);
  const [inputAddress, setInputAddress] = useState("");

  useEffect(() => {
    setInputAddress(address);
  }, [address]);

  const [local_latitude, setLat] = useState("");
  const [local_longitude, setLong] = useState("");

  useEffect(() => {
    if (window.google) {
      const autocomplete = new window.google.maps.places.Autocomplete(
        inputRef.current,
        {
          types: ['geocode', 'establishment'],
          componentRestrictions: { country: "in" },
          libraries:['Places']
        }
      );

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (place.geometry && place.geometry.location) {
          const { lat, lng } = place.geometry.location.toJSON();
          const formattedAddress = place.formatted_address;
          setInputAddress(formattedAddress);
          setLat(lat);
          setLong(lng);
          onPlaceSelected(lat, lng, formattedAddress); // Pass latitude and longitude
        }
      });
    }
  }, [onPlaceSelected]);

  const handleAddressChange = (e) => {
    const newAddress = e.target.value;
    setInputAddress(newAddress);
    if (!newAddress) {
      // Clear latitude and longitude if address is empty
      setLat("");
      setLong("");
      onPlaceSelected("", "", ""); // Trigger onPlaceSelected with empty values
    }
  };

  const handleLatitudeChange = (e) => {
    const newLatitude = e.target.value;
    setLat(newLatitude);
    // Perform reverse geocoding
    if (newLatitude && longitude) {
      reverseGeocode(newLatitude, longitude);
    }
    onPlaceSelected(newLatitude, longitude, address);  };

  const handleLongitudeChange = (e) => {
    const newLongitude = e.target.value;
    setLong(newLongitude);
    // Perform reverse geocoding
    if (latitude && newLongitude) {
      reverseGeocode(latitude, newLongitude);
    }
    onPlaceSelected(latitude, newLongitude, address);
    };

    const reverseGeocode = (lat, lng) => {
      const geocoder = new window.google.maps.Geocoder();
      const latlng = { lat: parseFloat(lat), lng: parseFloat(lng) };
  
      geocoder.geocode({ location: latlng }, (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            setInputAddress(results[0].formatted_address);
            onPlaceSelected(lat, lng, results[0].formatted_address);
          } else {
            console.log("No results found");
          }
        } else {
          console.error("Geocoder failed due to: " + status);
        }
      });
    };
  return (
    <>
      <input
        ref={inputRef}
        id="addressInput"
        style={{ width: "20%" }}
        placeholder="Enter Address"
        type="text"
        value={inputAddress}
        onChange={handleAddressChange}
      />
      <input
        style={{ width: "12%" }}
        type="text"
        placeholder="Latitude *"
        value={latitude}
        onChange={handleLatitudeChange}
      />
      <input
        style={{ width: "12%" }}
        type="text"
        placeholder="Longitude *"
        value={longitude}
        onChange={handleLongitudeChange}
      />
    </>
  );
};

export default AddressSearch;
