import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import "./App.css";
import Login from './component/Login';
import Home from './component/Home';
import About from './component/About';
import Footer from './Footer';
import Assets from './component/AssetManagement/Assets';
import Reports from './component/Reports';
import Users from './component/UserManagement/Users';
import Tasks from './component/TaskManagement/Tasks';
import Profile from './component/Profile';
import Settings from './component/Settings';
import PrivacyPolicy from './component/PrivacyPolicy';
import TermsConditions from './component/TermsConditions';
import * as Constants from "./utils/Constants";

function App() {

    const PrivateRoute = ({ element }) => {
        const isAuthenticated = localStorage.getItem(Constants.IS_AUTHENTICATED);
        return isAuthenticated ? (
            element
        ) : (
            <Navigate to="/login" replace />
        );
    };

    return (
        <div className="App">
            <BrowserRouter>
                <Routes>
                    <Route path="/home" element={<PrivateRoute element={<Home />} />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/terms-and-conditions" element={<TermsConditions />} />
                    <Route path="/" element={<Login />} />
                    <Route path="/assets" element={<PrivateRoute element={<Assets />} />} />
                    <Route path="/users" element={<PrivateRoute element={<Users />} />} />
                    <Route path="/tasks" element={<PrivateRoute element={<Tasks />} />} />
                    <Route path="/profile" element={<PrivateRoute element={<Profile />} />} />
                    <Route path="/reports" element={<PrivateRoute element={<Reports />} />} />
                    <Route path="/settings" element={<PrivateRoute element={<Settings />} />} />
                    <Route path="/about" element={<PrivateRoute element={<About />} />} />
                </Routes>
            </BrowserRouter>
            <Footer />
        </div>
    );
}

export default App;
