import React, { useState, useEffect } from 'react';
import * as Constants from "../utils/Constants";

const TableRow = ({ rowData, onClick, rowIndex, setSelectedRowIndex, selected }) => {
  const handleRowClick = () => {
    onClick(rowData);
    setSelectedRowIndex(rowIndex);
  };

  return (
    <tr
      onClick={handleRowClick}
      className={selected ? 'selected-row' : ''}
    >
      {Object.keys(rowData).map((key, index) => (
        <td key={index}>{rowData[key]}</td>
      ))}
    </tr>
  );
};

const ReusableTable = ({ tabledata, setTableData, headers, onRowClick }) => {
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);

  const handleSort = (key) => {
    const newDirection = sortConfig.key === key
      ? sortConfig.direction === 'asc' ? 'desc' : 'asc'
      : 'asc';
    setSortConfig({ key, direction: newDirection });
  };

  useEffect(() => {
    if (sortConfig.key) {
      const sortedData = [...tabledata].sort((a, b) => {
        const key = sortConfig.key;
        const sortOrder = sortConfig.direction === 'asc' ? 1 : -1;

        if (typeof a[key] === 'number' && typeof b[key] === 'number') {
          return (a[key] - b[key]) * sortOrder;
        } else {
          const aStr = String(a[key]).toLowerCase();
          const bStr = String(b[key]).toLowerCase();
          return (aStr < bStr ? -1 : 1) * sortOrder;
        }
      });

      // Update state with the new sorted array
      setTableData(sortedData);
    }
  }, [sortConfig,  setTableData]);

  return (
    <table className='custom-table'>
      <thead>
        <tr>
          {headers.map((header, index) => (
            <th key={index} onClick={() => handleSort(header)}>
              {header}
              {sortConfig.key === header && (
                <span>{sortConfig.direction === 'asc' ? Constants.SORT_UP : Constants.SORT_DOWN}</span>

              )}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tabledata.map((rowData, index) => (
          <TableRow
            key={index}
            rowData={rowData}
            onClick={onRowClick}
            rowIndex={index}
            setSelectedRowIndex={setSelectedRowIndex}
            selected={selectedRowIndex === index}
          />
        ))}
      </tbody>
    </table>
  );
};

export default ReusableTable;
