import React, { useState } from "react";
import '../home.css'
import {
    showToast,
    generateRandomNumber,
    getAuthToken,
    getClientIdOfUser,
    getLoggedInUserId
} from '../../utils/utility';

import * as UrlConstants from "../../utils/UrlConstants";
import axios from "axios";
import * as Constants from "../../utils/Constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCircleInfo
} from "@fortawesome/free-solid-svg-icons";

import { Tooltip } from 'react-tooltip';

export default function RegisterUser() {

    const [formData, setFormData] = useState({
        username: '',
        password: '',
        email: '',
        userRole: '',
        userStatus: 'ACTIVE',
        streetAddress: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
        uploadDate: new Date().toISOString(),
        idType: '',
        idNumber: '',
        fullName: '',
        phoneNumber: ''
    });

    // State variables for validation
    const [validationErrors, setValidationErrors] = useState({
        username: false,
        password: false,
        email: false,
        fullName: false,
        userRole: false,
        idType: false,
        idNumber: false
        // Add more fields for validation if needed
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        // Clear validation error when user starts typing
        setValidationErrors({ ...validationErrors, [name]: false });
    };

    function registerUser() {

        // Construct request body
        const requestBody = {
            requestId: generateRandomNumber().toString(),
            clientId: getClientIdOfUser(),
            userId: getLoggedInUserId(),
            requestBody: {
                ...formData,
                username: formData.email
            }
        };

        const errors = {};
        let hasErrors = false;

        // Check for empty fields
        for (const key in formData) {
            if (!formData[key] && key !== 'username') {
                errors[key] = true;
                hasErrors = true;
            }
        }

        if (hasErrors) {
            // Update validation errors state
            setValidationErrors(errors);
            showToast('Please fill in all required fields', { autoClose: Constants.TOAST_TIMEOUT, type: 'error', position: 'top-center' });
            return;
        }



        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getAuthToken()}`
        };

        const endPoint = `${Constants.API_V1}/${getClientIdOfUser()}/${UrlConstants.USERS}`

        axios.post(`${Constants.BASE_URL}${endPoint}`, requestBody, { headers })
            .then(response => {
                console.log('Response:', response.data);
                if (response.status === 200) {
                    showToast('User Registration successful', { autoClose: 1500, type: 'success', position: 'top-center' });
                } else {
                    showToast(response.data.responseMessage, { autoClose: 1500, type: 'warning', position: 'top-center' })
                }
            })
            .catch(error => {
                console.error('Error:', error);
                showToast(`User Registration failed: ${error.response.data.responseData}`, { autoClose: Constants.TOAST_TIMEOUT, type: 'error', position: 'top-center' });
            });

    }

    function clearUserDetails() {
        // Clear form data and validation errors
        setFormData({
            username: '',
            password: '',
            email: '',
            userRole: '',
            userStatus: 'ACTIVE',
            streetAddress: '',
            city: '',
            state: '',
            postalCode: '',
            country: '',
            uploadDate: '',
            idType: '',
            idNumber: '',
            fullName: '',
            phoneNumber: ''
        });
        setValidationErrors({
            username: false,
            password: false,
            email: false,
            fullName: false,
            userRole: false,
            idType: false,
            idNumber: false
            // Add more fields for validation if needed
        });
    }

    return (
        <div className="main-child">
            <div className="central-section container">
                <span className="section-title">User Registration</span>
                <div className="form-row">
                    <div className="form-column">
                        <div className="section-title">
                            <span>Details</span>
                        </div>
                        <div>
                            <div className="form-group">
                                <select
                                    id="userRole"
                                    name="userRole"
                                    value={formData.userRole}
                                    onChange={handleChange}
                                    className={validationErrors.userRole ? 'invalid' : ''}
                                >
                                    <option value="">Select User Role *</option>
                                    <option value="CLIENT_ADMIN">CLIENT_ADMIN</option>
                                    <option value="CLIENT_MANAGER">CLIENT_MANAGER</option>
                                    <option value="CLIENT_EMPLOYEE">CLIENT_EMPLOYEE</option>
                                </select>
                                {/* Display validation error message */}
                                {validationErrors.userRole && <span className="error-message">User Role is required</span>}</div>
                            <div className="form-group">
                                <input type="text" id="fullName" placeholder="Full Name *" name="fullName" value={formData.fullName} onChange={handleChange} className={validationErrors.fullName ? 'invalid' : ''} />
                                {/* Display validation error message */}
                                {validationErrors.fullName && <span className="error-message">Full Name is required</span>}
                            </div>
                            <div className="form-group"> <input type="text" id="idType" placeholder="Id Type *" name="idType" value={formData.idType} onChange={handleChange} className={validationErrors.idType ? 'invalid' : ''} />
                                {/* Display validation error message */}
                                {validationErrors.idType && <span className="error-message">Id Type is required</span>}</div>
                            <div className="form-group">  <input type="text" id="idNumber" placeholder="Id Number *" name="idNumber" value={formData.idNumber} onChange={handleChange} className={validationErrors.idNumber ? 'invalid' : ''} />
                                {/* Display validation error message */}
                                {validationErrors.idNumber && <span className="error-message">Id Number is required</span>}</div>
                        </div>
                    </div>
                    <div className="form-column">
                        <div className="section-title">
                            <span>Contact</span>
                        </div>

                        <div className="form-group"> <input type="tel" id="phoneNumber" placeholder="Phone Number *" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} className={validationErrors.phoneNumber ? 'invalid' : ''} />
                            {/* Display validation error message */}
                            {validationErrors.phoneNumber && <span className="error-message">Phone Number is required</span>}</div>
                        <div className="form-group"> <input type="text" id="streetAddress" placeholder="Street Address *" name="streetAddress" value={formData.streetAddress} onChange={handleChange} className={validationErrors.streetAddress ? 'invalid' : ''} />
                            {/* Display validation error message */}
                            {validationErrors.streetAddress && <span className="error-message">Street Address is required</span>}</div>
                        <div className="form-group"> <input type="text" id="city" placeholder="City *" name="city" value={formData.city} onChange={handleChange} className={validationErrors.city ? 'invalid' : ''} />
                            {/* Display validation error message */}
                            {validationErrors.city && <span className="error-message">City is required</span>}</div>
                        <div className="form-group"> <input type="text" id="state" placeholder="State *" name="state" value={formData.state} onChange={handleChange} className={validationErrors.state ? 'invalid' : ''} />
                            {/* Display validation error message */}
                            {validationErrors.state && <span className="error-message">State is required</span>}</div>
                        <div className="form-group"> <input type="text" id="postalCode" placeholder="Pin Code *" name="postalCode" value={formData.postalCode} onChange={handleChange} className={validationErrors.postalCode ? 'invalid' : ''} />
                            {/* Display validation error message */}
                            {validationErrors.postalCode && <span className="error-message">Pin Code is required</span>}
                        </div>
                        <div className="form-group"> <input type="text" id="country" placeholder="Country *" name="country" value={formData.country} onChange={handleChange} className={validationErrors.country ? 'invalid' : ''} />
                            {/* Display validation error message */}
                            {validationErrors.country && <span className="error-message">Country is required</span>}
                        </div>
                    </div>
                    <div className="form-column">
                        <div className="section-title">
                            <span>Login</span>
                        </div>
                        <div className="form-group"> <input type="email" id="email" placeholder="Email" name="email" value={formData.email} onChange={handleChange} className={validationErrors.email ? 'invalid' : ''} />
                            {/* Display validation error message */}
                            {validationErrors.email && <span className="error-message">Email is required</span>}</div>
                        <div className="form-group">
                            <input type="password" autoComplete="off" id="password" placeholder="Password" name="password" value={formData.password} onChange={handleChange} className={validationErrors.password ? 'invalid' : ''} />
                            {/* Display validation error message */}
                            {validationErrors.password && <span className="error-message">Password is required</span>}
                        </div>
                    </div>
                    <div className="align-center">
                        <button className="yekkada-btn" onClick={() => registerUser()}>Register</button>
                        <button className="yekkada-btn" onClick={() => clearUserDetails()}>Clear</button>
                        <FontAwesomeIcon style={{ color: "#363168" }} icon={faCircleInfo} size='xl' data-tooltip-id="my-tooltip" data-tooltip-content="Fields marked with * are mandatory" />
                    </div>
                </div>
                <Tooltip id="my-tooltip" />
            </div>
        </div>
    )
}
