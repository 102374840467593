import React from 'react';
import './yekkadaTable.css'

const TableRow = ({ rowData, onClick, rowIndex }) => {

  return (
    <tr>
      {Object.keys(rowData).map((key, index) => (
        <td key={index}>{rowData[key]}</td>
      ))}
    </tr>
  );
};

const DynamicTable = ({ tabledata, headers }) => {
  return (
    <table className='dynamic-table'>
      <thead>
        <tr>
          {headers.map((header, index) => (
            <th key={index}>
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tabledata.map((rowData, index) => (
          <TableRow
            key={index}
            rowData={rowData}
            rowIndex={index}
          />
        ))}
      </tbody>
    </table>
  );
};

export default DynamicTable;
