import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { customStyles } from "../../utils/DataTableCustomStyle";
import * as Constants from "../../utils/Constants";
import { getAuthToken, getClientIdOfUser, showToast, generateRandomNumber, getLoggedInUserId } from '../../utils/utility';
import TaskDetailsPanel from './TaskDetailsPanel';
import { Tooltip } from 'react-tooltip';

const TaskList = ({ onCopyTask }) => {
    const [tasks, setTasks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedTasks, setSelectedTasks] = useState([]);
    const [filteredTasks, setFilteredTasks] = useState([]);

    useEffect(() => {
        fetchTasks();
    }, []);

    useEffect(() => {
        setFilteredTasks(tasks);
    }, [tasks]);

    const fetchTasks = () => {
        const endPoint = `${Constants.API_V1}/${getClientIdOfUser()}/tasks`;

        fetch(`${Constants.BASE_URL}${endPoint}`, {
            method: 'GET',
            headers: {
                'Authorization': `${Constants.BEARER} ${getAuthToken()}`
            }
        })
            .then(response => response.json())
            .then(data => {
                setTasks(data.responseData);
                setLoading(false);
                setSelectedTasks([]); // Reset selected tasks here
                setFilteredTasks([]);
                document.getElementById("status-filter").value = "All";
            })
            .catch(error => console.error('Error fetching tasks:', error));
    };

    const editTask = (taskIds, status) => {
        const requestBody = {
            requestBody: {
                taskIds: taskIds,
                taskStatus: status
            },
            requestId: generateRandomNumber().toString(),
            clientId: getClientIdOfUser(),
            userId: getLoggedInUserId(),
        };

        const endpoint = `${Constants.BASE_URL}${Constants.API_V1}/${getClientIdOfUser()}/tasks/updateTaskList`;

        fetch(endpoint, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getAuthToken()}`
            },
            body: JSON.stringify(requestBody)
        })
            .then(response => response.json())
            .then(data => {
                console.log(data);
                console.log('Task status updated successfully');
                showToast(data.responseData, { autoClose: 3000, type: 'success', position: 'top-center' });
                setSelectedTasks([]); // Clear selected tasks after editing task
                window.location.reload();
            })
            .catch(error => {
                console.error('Error updating task status:', error);
            });
    };

    const clearSelectAllRowCheckBox = () => {
        const selectAllCheckbox = document.querySelector('[name="select-all-rows"]');

        // Set the checked attribute to false to uncheck the checkbox
        if (selectAllCheckbox) {
            selectAllCheckbox.checked = false;
        } else {
            console.error("Checkbox with name 'select-all-rows' not found.");
        }
        setFilteredTasks([]);
        setSelectedTasks([]);
    };

    const handleRowSelected = (state) => {
        setSelectedTasks(state.selectedRows.map(row => row.taskId));
    };

    const handleStatusFilterChange = (e) => {
        const status = e.target.value;
        if (status === 'All') {
            setFilteredTasks(tasks);
        } else {
            const filtered = tasks.filter(task => task.taskStatus === status);
            setFilteredTasks(filtered);
        }
    };

    const handleStartButtonClick = () => {
        editTask(selectedTasks, 'IN_PROGRESS');
    };

    const handleCompleteButtonClick = () => {
        editTask(selectedTasks, 'COMPLETED');
    };

    const handleCancelButtonClick = () => {
        editTask(selectedTasks, 'CANCELLED');
    };

    const columns = [
        {
            name: 'Task ID',
            selector: (row) => row.taskId,
            sortable: true,
            grow: 0.4
        },
        {
            name: 'Device ID',
            selector: (row) => row.deviceId,
            sortable: true,
            grow: 0.55
        },
        {
            name: 'Title',
            selector: (row) => row.title,
            sortable: true,
        },
        {
            name: 'Status',
            selector: (row) => row.taskStatus,
            sortable: true,
            grow: 0.55
        },
        {
            name: 'Start Time',
            selector: (row) => row.startTime,
            sortable: true,
            cell: row => row.startTime ? new Date(row.startTime).toLocaleString('en-IN', { timeZone: 'UTC' }) : 'N/A',
            grow: 0.75
        },
        {
            name: 'End Time',
            selector: (row) => row.endTime,
            sortable: true,
            cell: row => row.endTime ? new Date(row.endTime).toLocaleString('en-IN', { timeZone: 'UTC' }) : 'N/A',
            grow: 0.75
        },
        {
            name: 'Geo fence(s) Configured',
            selector: (row) => row.personalized,
            sortable: true,
            wrap: true,
            className: 'wrap-header-text',
        },
        {
            name: 'Entity assigned',
            selector: (row) => row.assignedEntity || 'None',
            sortable: true,
            wrap: true,
            grow: 0.65,
        },
        {
            name: 'Actions',
            cell: (row) => (
                <button onClick={() => onCopyTask(row)}>Copy</button>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        }
    ];

    return (
        <div className="main-child">
            <div className="central-section container scrollable-y">
                <div className="task-list">
                    <div className='section-title'><span>Task List</span></div>
                    <div>
                        <label htmlFor="status-filter">Filter by Status: </label>
                        <select id="status-filter" onChange={handleStatusFilterChange}>
                            <option value="All">All</option>
                            <option value="ASSIGNED">Assigned</option>
                            <option value="IN_PROGRESS">In Progress</option>
                            <option value="COMPLETED">Completed</option>
                            <option value="CANCELLED">Cancelled</option>
                        </select>
                    </div>
                    <DataTable
                        columns={columns}
                        data={filteredTasks}
                        pagination
                        highlightOnHover
                        striped
                        responsive
                        progressPending={loading}
                        progressComponent={<h3>Loading...</h3>}
                        expandableRows
                        selectableRows // Enable row selection
                        selectableRowsHighlight // Highlight selected rows
                        onSelectedRowsChange={handleRowSelected} // Callback for handling row selection
                        expandableRowsComponent={(row) => <TaskDetailsPanel data={row} />}
                        customStyles={customStyles}
                    />
                </div>
                <div className="button-container">
                    <button className="yekkada-btn"
                        onClick={handleStartButtonClick}
                        disabled={
                            selectedTasks.length === 0 ||
                            selectedTasks.some(taskId => {
                                const task = tasks.find(task => task.taskId === taskId);
                                return task?.taskStatus !== 'ASSIGNED' || task?.personalized === 'NO';
                            })
                        }
                    >
                        Start Tasks
                    </button>
                    &nbsp;&nbsp;
                    <button className="yekkada-btn" onClick={handleCompleteButtonClick}
                        disabled={
                            selectedTasks.length === 0 ||
                            selectedTasks.some(taskId => {
                                const task = tasks.find(task => task.taskId === taskId);
                                return task?.taskStatus !== 'IN_PROGRESS' || task?.personalized === 'NO';
                            })
                        }
                    >
                        Complete Tasks
                    </button>
                    &nbsp;&nbsp;
                    <button className="yekkada-btn" onClick={handleCancelButtonClick}
                        disabled={
                            selectedTasks.length === 0 ||
                            selectedTasks.some(taskId => {
                                const task = tasks.find(task => task.taskId === taskId);
                                return task && (task.taskStatus === 'COMPLETED' || task.taskStatus === 'CANCELLED');
                            })
                        }
                    >
                        Cancel Tasks
                    </button>
                </div>
            </div>
            <Tooltip id="my-tooltip" />
        </div>
    );
};

export default TaskList;
