import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  generateRandomNumber,
  getLoggedInUserId,
  formatDateToDDMMYYYY,
  getAuthToken,
  getClientIdOfUser,
  showToast,
} from "../../utils/utility";
import * as Constants from "../../utils/Constants";
import * as UrlConstants from "../../utils/UrlConstants";

const ProfilePage = () => {
  const [userData, setUserData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [formState, setFormState] = useState({});

  useEffect(() => {
    // Fetch user data on component mount
    const fetchUserData = async () => {
      let endPoint = `${
        Constants.API_V1
      }/${getClientIdOfUser()}/users/${getLoggedInUserId()}`;

      try {
        const response = await axios.get(`${Constants.BASE_URL}${endPoint}`);
        setUserData(response.data.responseData);
        setFormState(response.data.responseData);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    let endPoint = `${
      Constants.API_V1
    }/${getClientIdOfUser()}/users/${getLoggedInUserId()}`;


    try {
      const response = await axios.put(`${Constants.BASE_URL}${endPoint}`, formState);
      setUserData(response.data.responseData);
      setIsEditing(false);
      showToast("Profile updated successfully", "success"); // Example showToast usage
    } catch (error) {
      console.error("Error updating user data:", error);
      showToast("This feature is under development", "error"); // Example showToast usage
    }
  };

  if (!userData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="main-child">
      <div className="central-section container scrollable-y">
        <span className="section-title">Profile Page</span>

        <div className="profile-form">
          <div className="form-row">
            <div className="form-column">
              <div className="form-group">
                <label>
                  Full Name:
                  <input
                    type="text"
                    name="fullName"
                    value={formState.fullName || ""}
                    onChange={handleChange}
                    disabled={!isEditing}
                  />
                </label>
              </div>
              <div className="form-group">
                <label>
                  Email:
                  <input
                    type="email"
                    name="email"
                    value={formState.email || ""}
                    onChange={handleChange}
                    disabled={!isEditing}
                  />
                </label>
              </div>
              <div className="form-group">
                <label>
                  Phone Number:
                  <input
                    type="text"
                    name="phoneNumber"
                    value={formState.phoneNumber || ""}
                    onChange={handleChange}
                    disabled={!isEditing}
                  />
                </label>
              </div>
              <div className="form-group">
                <label>
                  Street Address:
                  <input
                    type="text"
                    name="streetAddress"
                    value={formState.streetAddress || ""}
                    onChange={handleChange}
                    disabled={!isEditing}
                  />
                </label>
              </div>
            </div>

            <div className="form-column">
              <div className="form-group">
                <label>
                  City:
                  <input
                    type="text"
                    name="city"
                    value={formState.city || ""}
                    onChange={handleChange}
                    disabled={!isEditing}
                  />
                </label>
              </div>
              <div className="form-group">
                <label>
                  State:
                  <input
                    type="text"
                    name="state"
                    value={formState.state || ""}
                    onChange={handleChange}
                    disabled={!isEditing}
                  />
                </label>
              </div>
              <div className="form-group">
                <label>
                  Postal Code:
                  <input
                    type="text"
                    name="postalCode"
                    value={formState.postalCode || ""}
                    onChange={handleChange}
                    disabled={!isEditing}
                  />
                </label>
              </div>
              <div className="form-group">
                <label>
                  Country:
                  <input
                    type="text"
                    name="country"
                    value={formState.country || ""}
                    onChange={handleChange}
                    disabled={!isEditing}
                  />
                </label>
              </div>
            </div>
            <div className="register-btn-div">
              <button className="yekkada-btn" type="button" onClick={() => setIsEditing(!isEditing)}>
                {isEditing ? "Cancel" : "Edit"}
              </button>
              {isEditing && (
                <button className="yekkada-btn" type="button" onClick={handleSubmit}>
                  Save
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
