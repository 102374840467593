import React, { useState } from "react";
import HomeSideNavBar from "../HomeSideNavBar";
import Header from "../Header";
import "../home.css"
import ApiDataProvider from "../ApiDataContext";
import ListUsers from "./ListUsers";
import RegisterUser from "./RegisterUser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faUsers } from "@fortawesome/free-solid-svg-icons";

function Users() {

    const LIST_USERS = 'UserList'
    const REGISTER_USER = 'RegisterUser'

    const [selectedComponent, setSelectedComponent] = useState(LIST_USERS);


    const renderSelectedComponent = () => {
        switch (selectedComponent) {
            case LIST_USERS:
                return <ListUsers />;
            case REGISTER_USER:
                return <RegisterUser />;
            // case 'C':
            //     return <ComponentC />;
            default:
                return null;
        }
    };

    return (
        <ApiDataProvider>
            <div className="container">
                <div className="screen-layout">
                    <Header />
                    <HomeSideNavBar />
                    <div className="main-section-div" >
                        <div className="report-nav-bar">
                            <button
                                className={selectedComponent === LIST_USERS ? 'selected' : ''}
                                onClick={() => setSelectedComponent(LIST_USERS)}>
                                <FontAwesomeIcon style={{ color: '#363168' }} icon={faUsers} /> &nbsp;User List
                            </button>
                            <button
                                className={selectedComponent === REGISTER_USER ? 'selected' : ''}
                                onClick={() => setSelectedComponent(REGISTER_USER)}>
                                <FontAwesomeIcon style={{ color: '#363168' }} icon={faFile} /> &nbsp;Register User
                            </button>
                        </div>
                        {renderSelectedComponent()}
                    </div>
                </div>
            </div>
        </ApiDataProvider>
    )
}

export default Users