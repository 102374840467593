import React from 'react';

function AssetTypeSelector({ value, onChange }) {
  return (
    <select
      id="assetType"
      name="assetType"
      value={value}
      onChange={onChange}
      required
    >
      <option value="">Select Asset Type *</option>
      <option value="ELOCK">Elock</option>
      <option value="GPS_TRACKER">Gps Tracker</option>
      <option value="SCHOOL_BUS">School Bus</option>
      <option value="EMPLOYEE_DEVICE">Mobile Phone</option>
    </select>
  );
}

export default AssetTypeSelector;
