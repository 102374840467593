import React from 'react';
import './UnderDevelopment.css'; // Import the CSS file

const UnderDevelopment = ({ message = 'This page is under development.' }) => {
  return (
    <div className="under-development main-section-div">
      <h2>{message}</h2>
      <p>We're working hard to bring you this feature soon.</p>
      <img src="https://th.bing.com/th/id/OIP.PLwrRqy34tlZHSOdnUHo8wHaHa?w=480&h=480&rs=1&pid=ImgDetMain" alt="Under development icon" />
    </div>
  );
};

export default UnderDevelopment;
