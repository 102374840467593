import React from 'react';
const PrivacyPolicy = () => {
    return (

        <div className="container">
            <div className="screen-layout ">
                <div className='col-central-content'>
                    <h1>Privacy Policy</h1>
                    <br />
                    <h2>1. Introduction</h2>
                    <p>
                        YEKKADA – GET TRACKKING! is an any kind of asset/people tracking platform powered by Satyology Solutions Private Limited.
                        This Privacy Policy is designed to help you understand how we collect, use, and safeguard your information.
                        By using our platform, you agree to the terms outlined in this policy.
                    </p>

                    <h2>2. Information We Collect</h2>
                    <h3>2.1. Personal Information</h3>
                    <p>
                        We may collect personal information such as Name, Email address, Postal Address, Company name, IP address,
                        Browser cookies, and Contact details when you register directly to Satyology Solutions or through our partners or services providers for our platform or use specific features.
                    </p>
                    <h3>2.2. Location Information</h3>
                    <p>
                        Our asset/people tracking platform may collect location data to provide real-time asset/people tracking services.
                        Users can control location settings through the platform's settings.
                    </p>
                    <h3>2.3. Device Information</h3>
                    <p>
                        We may collect information about the devices used to access our platform, such as device type, operating system, and unique device identifiers.
                    </p>

                    <h2>3. How We Use Your Information</h2>
                    <h3>3.1. Service Improvement</h3>
                    <p>We use the collected data to improve our asset tracking services, enhance user experience, and develop new features.</p>
                    <h3>3.2. Communication</h3>
                    <p>
                        We may use your contact information to send important updates, notifications, and marketing materials.
                        Users can opt-out of promotional communications.
                    </p>
                    <h3>3.3. Legal Compliance</h3>
                    <p>We process data to comply with applicable laws, regulations, and legal requests.</p>

                    <h2>4. Data Sharing and Third Parties</h2>
                    <h3>4.1. Third-Party Service Providers</h3>
                    <p>
                        We may share information with third-party service providers to assist with functions such as data storage, analytics, and customer support.
                        We also share Personal Data when necessary to complete a transaction initiated or authorized by you or provide you with a product or service you have requested.
                    </p>
                    <h3>4.2. Legal Requirements</h3>
                    <p>We may disclose information if required by law or in response to legal requests.</p>

                    <h2>5. User Controls and Rights</h2>
                    <h3>5.1. Access and Correction</h3>
                    <p>Users have the right to access and correct their personal information.</p>
                    <h3>5.2. Deletion</h3>
                    <p>Users can request the deletion of their account and associated data.</p>

                    <h2>6. Retention of Data</h2>
                    <p>
                        We will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy.
                        We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws),
                        resolve disputes, and enforce our legal agreements and policies.
                    </p>
                    <p>
                        We will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period,
                        except when this data is used to strengthen the security or to improve the functionality of our Service,
                        or we are legally obligated to retain this data for longer time periods.
                    </p>

                    <h2>7. Transfer of Data</h2>
                    <p>
                        Your information, including Personal Data, may be transferred to – and maintained on – computers located outside of your state, province, country,
                        or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.
                    </p>
                    <p>
                        If you are located outside India and choose to provide information to us, please note that we transfer the data, including Personal Data, to India and process it there.
                    </p>
                    <p>
                        Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.
                        Satyology Solutions will take all the steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy
                        and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.
                    </p>

                    <h2>8. Disclosure of Data</h2>
                    <p>We may disclose personal information that we collect, or you provide: If requested by legal authorities. Other than them we will not disclose your data.</p>
                    <h3>8.1. Disclosure for Law Enforcement.</h3>
                    <p>
                        Under certain circumstances, we may be required to disclose your Personal Data if required to do so by law or in response to valid requests by public authorities.
                    </p>
                    <h3>8.2. Business Transaction.</h3>
                    <p>Your Personal Data may be transferred if we or our subsidiaries are involved in a merger, acquisition, or asset sale.</p>
                    <h3>8.3. Other cases.</h3>
                    <p>We may disclose your information also:</p>
                    <ul>
                        <li>8.3.1. to our subsidiaries and affiliates;</li>
                        <li>8.3.2. to contractors, service providers, and other third parties we use to support our business;</li>
                        <li>8.3.3. to fulfill the purpose for which you provide it;</li>
                        <li>8.3.4. for the purpose of including your company’s logo on our website;</li>
                        <li>8.3.5. for any other purpose disclosed by us when you provide the information;</li>
                        <li>8.3.6. with your consent in any other cases;</li>
                        <li>8.3.7. if we believe disclosure is necessary or appropriate to protect the rights, property, or safety of the Company, our customers, or others.</li>
                    </ul>

                    <h2>9. Data Security</h2>
                    <p>We implement industry-standard security measures to protect user data, including encryption, access controls, and regular security audits.</p>

                    <h2>10. Service Providers</h2>
                    <p>
                        We may employ third-party companies and individuals to facilitate our Service (“Service Providers”), provide Service on our behalf,
                        perform Service-related services, or assist us in analyzing how our Service is used.
                    </p>
                    <p>
                        These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.
                    </p>

                    <h2>11. Analytics</h2>
                    <p>We may use third-party Service Providers to monitor and analyze the use of our Service.</p>

                    <h2>12. Payments</h2>
                    <p>
                        We may provide paid products and/or services within Service. In that case, we use third-party services for payment processing (e.g., payment processors).
                    </p>
                    <p>
                        We will not store or collect your payment card details. That information is provided directly to our third-party payment processors whose use of your personal information is governed by their Privacy Policy.
                        These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express, and Discover.
                        PCI-DSS requirements help ensure the secure handling of payment information.
                    </p>

                    <h2>13. Cookies and Tracking Technologies</h2>
                    <p>We use cookies and similar technologies to enhance user experience. Users can manage cookie preferences through their browser settings.</p>

                    <h2>14. Updates to Privacy Policy</h2>
                    <p>We reserve the right to update this Privacy Policy. Users will be notified of any material changes.</p>

                    <h2>15. Contact Information</h2>
                    <p>For privacy-related inquiries or concerns, please contact us at help@satyology.com or +91 7406 646464.</p>
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicy;