import React, { useState } from 'react';
import AssetTypeSelector from './AssetTypeSelector';
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleInfo,
  faFileArrowUp,
  faPlus,
  faTrashCan
} from "@fortawesome/free-solid-svg-icons";

import {
  showToast,
  generateRandomNumber,
  getAuthToken,
  getClientIdOfUser,
  getLoggedInUserId
} from '../../utils/utility';

import { Tooltip } from 'react-tooltip';

import * as UrlConstants from "../../utils/UrlConstants";
import * as Constants from "../../utils/Constants";

function RegisterAsset() {
  const [assets, setAssets] = useState([{ name: '', description: '', assetType: '', assetUnitId: '', assetPassword: '' }]);


  const handleAssetChange = (index, field, value) => {
    // If validation passes or field is not assetPassword, update the state with the new value
    const updatedAssets = [...assets];
    updatedAssets[index][field] = value;
    setAssets(updatedAssets);
  };

  const handleAddAsset = () => {
    setAssets([...assets, { name: '', description: '', assetType: '', assetUnitId: '', assetPassword: '' }]);
  };

  const handleRemoveAsset = (index) => {
    const updatedAssets = [...assets];
    updatedAssets.splice(index, 1);
    setAssets(updatedAssets);
  };

  const registerAssets = () => {
    // Check if all mandatory fields are filled and perform password length validation
    const isValid = assets.every(asset => {
      if (!asset.name || !asset.assetUnitId || !asset.assetType) {
        showToast('Please fill in all mandatory fields.', { autoClose: Constants.TOAST_TIMEOUT, type: 'error', position: 'top-center' });
        return false;
      }
      if (asset.assetType === 'ELOCK' && asset.assetPassword.length !== 6) {
        showToast('Password must be exactly 6 characters for ELOCK asset type.', { autoClose: Constants.TOAST_TIMEOUT, type: 'error', position: 'top-center' });
        return false;
      }
      return true;
    });

    // If validation fails, return without registering assets
    if (!isValid) {
      return;
    }

    if (assets.length === 0) {
      showToast('Please add atleast one device for registration', { autoClose: Constants.TOAST_TIMEOUT, type: 'error', position: 'top-center' });
      return;
    }




    // Construct request body
    const requestBody = {
      requestId: generateRandomNumber().toString(),
      clientId: getClientIdOfUser(),
      userId: getLoggedInUserId(),
      requestBody: assets.map(asset => {
        if (asset.assetPassword) {
          return {
            ...asset,
            assetPassword: btoa(asset.assetPassword)
          };
        } else {
          return asset;
        }
      })
    };


    // Send the assets data to your platform using an API call
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${getAuthToken()}`
    };
    const endPoint = `${Constants.API_V1}/${getClientIdOfUser()}/${UrlConstants.ASSETS}/${UrlConstants.REGISTER_ASSETS}`;

    axios.post(`${Constants.BASE_URL}${endPoint}`, requestBody, { headers })
      .then(response => {
        console.log('Response:', response.data);
        if (response.status === 200) {
          showToast('Asset(s) Registration successful', { autoClose: Constants.TOAST_TIMEOUT, type: 'success', position: 'top-center' });
        } else {
          showToast(response.data.responseMessage, { autoClose: Constants.TOAST_TIMEOUT, type: 'warning', position: 'top-center' });
        }
      })
      .catch(error => {
        console.error('Error:', error);
        showToast(`Asset Registration failed: ${error.response.data.responseData}`, { autoClose: Constants.TOAST_TIMEOUT, type: 'error', position: 'top-center' });
      });

    // Clear the form after successful registration
    setAssets([{ name: '', description: '', assetType: '', assetUnitId: '', assetPassword: '' }]);
  };


  return (
    <div className="main-child">
      <div className="central-section container scrollable-y">
        <div  className='section-title'><span>Asset(s) Registration</span></div><br />
        <div className="form-row">
          {assets.map((asset, index) => (
            <div key={index} className='form-column'>
              <input
                type="text"
                placeholder="Asset Name *"
                value={asset.name}
                onChange={(event) => handleAssetChange(index, 'name', event.target.value)}
                required
              />
              <input
                type="text"
                placeholder="Asset Id *"
                value={asset.assetUnitId}
                onChange={(event) => handleAssetChange(index, 'assetUnitId', event.target.value)}
                required
              />
              <AssetTypeSelector
                value={asset.assetType}
                onChange={(event) => handleAssetChange(index, 'assetType', event.target.value)}
              />
              <input
                type="text"
                placeholder="Description"
                value={asset.description}
                onChange={(event) => handleAssetChange(index, 'description', event.target.value)}
                required
              />
              {asset.assetType === 'ELOCK' && (
                <input
                  type="password"
                  placeholder="Password *"
                  value={asset.assetPassword}
                  onChange={(event) => handleAssetChange(index, 'assetPassword', event.target.value)}
                  pattern="[0-9a-fA-F]{4}"
                  required
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="password is combination of any 6 characters"
                />
              )}
              <button className="remove-asset-btn icon-button" onClick={() => handleRemoveAsset(index)}>
                <FontAwesomeIcon style={{ color: "#363168" }} icon={faTrashCan} size='xl' />
              </button>
            </div>
          ))}

          <div className='register-btn-div'>
            <button className="yekkada-btn" onClick={() => registerAssets()}>
              <FontAwesomeIcon style={{ color: "#FFF" }} icon={faFileArrowUp} />&nbsp; Register Assets
            </button>
            <button className="add-asset-btn yekkada-btn" onClick={handleAddAsset}>
              <FontAwesomeIcon style={{ color: "#FFF" }} icon={faPlus} />&nbsp; Add Asset
            </button>
            <FontAwesomeIcon style={{ color: "#363168" }} icon={faCircleInfo} size='xl' data-tooltip-id="my-tooltip" data-tooltip-content="Fields marked with * are mandatory" />
          </div>
        </div>
      </div>
      <Tooltip id="my-tooltip" />
    </div>
  );
}

export default RegisterAsset;
