export const customStyles = {

    headRow: {
        style: {
            background: '#FF683A', // Header row background color
        },
    },
    headCells: {
        style: {
            color: 'white', // Header cell text color
            border: '1px solid #ddd',
            justifyContent: 'left',
        },
    },
    rows: {
        style: {
            fontSize: '12px', // Adjust font size for regular rows
            maxHeight: '25px',
        },
    },
    cells: {
        style: {
            border: '1px solid #ddd', // Add a subtle border
            whiteSpace: 'normal', // Allow text wrapping
            wordWrap: 'break-word', // Break words if necessary
            justifyContent: 'left',
        },
    },
    table: {
        style: {
            width: '100%', // Ensure table takes full width
            borderCollapse: 'collapse', // Collapse borders for tighter spacing
        },
    },
    subHeader: {
        style: {
            padding: '10px 5px', // Set padding for the subHeader
        },
    },
    expandableRows: {
        style: {
            background: '#f9f9f9', // Background color for expanded rows
        },
    },
    expandableRowsContent: {
        style: {
            padding: '10px', // Padding for the content inside expanded rows
        },
    },
};