import React from 'react';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <a href='/terms-and-conditions'>Terms & condtions</a>
        <span className="footer-copyright">Copyright © 2023-2024 Yekkada.com powered by Satyology Solutions Private Limited</span>
        <a href='/privacy-policy'>Privacy Policy</a>
      </div>
    </footer>
  );
};

export default Footer;
