import axios from 'axios';
import * as Constants from './Constants'
import { toast } from 'react-toastify';
import { jwtDecode } from 'jwt-decode';
import { logoutFun } from '../component/HomeSideNavBar';

export function generateColors(numColors) {
    // Create an empty array to store the generated colors.
    const colors = [];

    // Generate a random number between 0 and 255 for each color channel (red, green, blue).
    for (let i = 0; i < numColors; i++) {
        const red = Math.floor(Math.random() * 256);
        const green = Math.floor(Math.random() * 256);
        const blue = Math.floor(Math.random() * 256);

        // Add the generated color to the colors array.
        colors.push(`rgb(${red}, ${green}, ${blue})`);
    }

    // Return the colors array.
    return colors;
}


export function parseJsonIfNotEmpty(input) {

    // Check if the trimmed input has a length greater than zero
    if (null != input) {
        const trimmedInput = input.trim();
        try {
            // Attempt to parse the trimmed input as JSON
            const parsedJson = JSON.parse(trimmedInput);
            return parsedJson;
        } catch (error) {
            // JSON parsing failed, handle the error if needed
            console.error("Error parsing JSON:", error);
            return null; // Return null or handle the error case accordingly
        }
    } else {
        // Return null for empty input
        return null;
    }
}


export function getCurrentTimeMillis() {
    return Date.now();
}

export async function getGeolocationFromLatLng(latitude, longitude) {
    try {
        let endpoint = Constants.GMAP_LOCATION_API_URL;

        if (latitude === undefined || latitude === null || latitude === ""
            || longitude === undefined || longitude === null || longitude === "") {

            return "Invalid input";
        }

        const config = {
            transformRequest: [(data, headers) => {
                delete headers.Authorization;
                return data;
            }],
        };

        endpoint = endpoint + 'key=' + process.env.REACT_APP_GMAP_API_KEY + "&latlng=" + latitude + ',' + longitude;

        return axios.get(endpoint, config)
            .then(response => {
                // Handle the response.
                if (response.data.results.length > 0) {
                    console.log(response.data.results[0].formatted_address);
                    return response.data.results[0].formatted_address;
                } else {
                    throw new Error('Failed to fetch location details: ' + response.data.status);
                }
            })
            .catch(error => {
                // Handle the error.
                console.error(error);
                return null; // Return null if location details cannot be fetched
            });
    } catch (error) {
        console.error(error);
        return null; // Return null if location details cannot be fetched
    }
}


export function formatDateToDDMMYYYY(inputDate) {
    // Parse the input date in yyyy-mm-dd format
    const parsedDate = new Date(inputDate);

    // Check if the parsing was successful
    if (isNaN(parsedDate.getTime())) {
        throw new Error('Invalid date format');
    }

    // Extract day, month, and year components
    const day = String(parsedDate.getDate()).padStart(2, '0');
    const month = String(parsedDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = parsedDate.getFullYear();

    // Assemble the date in dd/mm/yyyy format
    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
}

export function getClientIdOfUser() {
    const loggedInUser = JSON.parse(localStorage.getItem(Constants.LOGGED_IN_USER));
    if (!loggedInUser || !loggedInUser.clientId) {
        logoutFun(); // Call the logout function if loggedInUser or clientId is null
    } else {
        return loggedInUser.clientId;
    }
}


export function getLoggedInUserId() {
    const loggedInUser = JSON.parse(localStorage.getItem(Constants.LOGGED_IN_USER));
    if (!loggedInUser || !loggedInUser.userId) {
        logoutFun(); // Call the logout function if loggedInUser or userId is null
    } else {
        return loggedInUser.userId;
    }
}


export function getAuthToken() {

    try {
        const storedToken = localStorage.getItem(Constants.LOGGED_IN_USER);

        if (!storedToken) {
            return null; // Token not found
        }

        const decodedToken = jwtDecode(storedToken);

        if (!decodedToken || !decodedToken.exp) {
            // Remove expired token from storage
            localStorage.removeItem(Constants.LOGGED_IN_USER);
            return null;  // Token invalid or missing expiration
        }

        const currentTime = Date.now() / 1000;
        if (decodedToken.exp < currentTime) {
            // Remove expired token from storage
            localStorage.removeItem(Constants.LOGGED_IN_USER);
            return null; // Token expired
        }

        return JSON.parse(storedToken).jwtToken; // Token is valid
    } catch (error) {
        console.error("Error decoding token:", error);
        localStorage.removeItem(Constants.LOGGED_IN_USER);
        return null; // Handle potential token errors
    }
}

export async function getDataFromBackend(endPoint) {
    axios.defaults.headers.common['Authorization'] = `${Constants.BEARER} ${getAuthToken()}`;

    return await axios.get(`${Constants.BASE_URL}${endPoint}`);
}

export const showToast = (message, options = {}) => {
    toast(message, options);
};

export function generateRandomNumber() {
    // Generate a random number between 100000000000 and 999999999999
    return Math.floor(Math.random() * 900000000000) + 100000000000;
}