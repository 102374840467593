import React, { useState, useEffect } from "react";
import axios from "axios";
import { getAuthToken, getClientIdOfUser } from "../../utils/utility";
import {
  Container,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import * as Constants from "../../utils/Constants";
import * as UrlContants from "../../utils/UrlConstants";
import "./mapping.css";

const MappingComponent = () => {
  const [customers, setCustomers] = useState([]);
  const [devices, setDevices] = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState("");
  const [mappings, setMappings] = useState([]);
  const [selectAll, setSelectAll] = useState(false); // State for Select All checkbox

  useEffect(() => {
    fetchCustomers();
    fetchDevices();
    fetchMappings();
  }, [selectedDevice]);

  const fetchCustomers = async () => {
    const endPoint = `${Constants.BASE_URL}${
      Constants.API_V1
    }/${getClientIdOfUser()}/customers`;
    axios.defaults.headers.common["Authorization"] = `${
      Constants.BEARER
    } ${getAuthToken()}`;
    const response = await axios.get(endPoint);
    setCustomers(response.data.responseData);
  };

  const fetchDevices = async () => {
    const endPoint = `${Constants.BASE_URL}${
      Constants.API_V1
    }/${getClientIdOfUser()}/assets/getAllAssets`;
    axios.defaults.headers.common["Authorization"] = `${
      Constants.BEARER
    } ${getAuthToken()}`;
    const response = await axios.get(endPoint);
    setDevices(response.data.responseData);
  };

  const fetchMappings = async () => {
    if (!selectedDevice) {
      return;
    }
    const endPoint = `${Constants.BASE_URL}${
      Constants.API_V1
    }/${getClientIdOfUser()}/customer-asset/list/${selectedDevice}`;
    axios.defaults.headers.common["Authorization"] = `${
      Constants.BEARER
    } ${getAuthToken()}`;
    const response = await axios.get(endPoint);
    setMappings(response.data.responseData);
    setSelectedCustomers([]);
    setSelectAll(false); // Reset select all checkbox
  };

  const handleCustomerChange = (event) => {
    setSelectedCustomers(event.target.value);
  };

  const handleDeviceChange = (event) => {
    setSelectedDevice(event.target.value);
  };

  const handleSubmit = async () => {
    const endPoint = `${Constants.BASE_URL}${
      Constants.API_V1
    }/${getClientIdOfUser()}/customer-asset/map?assetId=${selectedDevice}`;
    axios.defaults.headers.common["Authorization"] = `${
      Constants.BEARER
    } ${getAuthToken()}`;
    const customerIds = selectedCustomers.map(
      (customer) => customer.customerId
    );
    await axios.post(endPoint, customerIds);
    fetchMappings();
  };

  const handleDelete = async (mappingId) => {
    const endPoint = `${Constants.BASE_URL}${
      Constants.API_V1
    }/${getClientIdOfUser()}/customer-asset/unmap/${mappingId}`;
    axios.defaults.headers.common["Authorization"] = `${
      Constants.BEARER
    } ${getAuthToken()}`;
    await axios.delete(endPoint);
    fetchMappings();
  };

  const handleSelectAllChange = (event) => {
    setSelectAll(event.target.checked);
    if (event.target.checked) {
      setSelectedCustomers(customers);
    } else {
      setSelectedCustomers([]);
    }
  };

  const isSelected = (customerId) => {
    return selectedCustomers.findIndex((c) => c.customerId === customerId) > -1;
  };

  return (
    <div className="main-child">
      <div className="central-section padded-container scrollable-y">
        <Container>
          <div className="section-title">Map Customers to Device</div>
          <FormControl
            fullWidth
            margin="dense"
            sx={{ mb: 2, background: "#FFF" }}
            variant="standard"
          >
            <InputLabel style={{ fontSize: "small" }}>Device</InputLabel>
            <Select value={selectedDevice} onChange={handleDeviceChange}>
              {devices.map((device) => (
                <MenuItem key={device.assetId} value={device.assetId}>
                  <p style={{ fontSize: "12px" }}>
                    {device.name}({device.assetId})
                  </p>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            fullWidth
            margin="dense"
            sx={{ mb: 2, fontSize: "12px" }}
            variant="standard"
          >
            <InputLabel style={{ fontSize: "12px" }}>Customers</InputLabel>
            <Select
              multiple
              value={selectedCustomers}
              onChange={handleCustomerChange}
              renderValue={(selected) =>
                selected
                  .map((c) => `${c.customerName} (${c.customerId})`)
                  .join(", ")
              }
              sx={{ fontSize: "12px" }}
            >
              <MenuItem sx={{ fontSize: "12px" }} value="select-all">
                <Checkbox
                  checked={selectAll}
                  indeterminate={
                    selectedCustomers.length > 0 &&
                    selectedCustomers.length < customers.length
                  }
                  onChange={handleSelectAllChange}
                  size="small"
                />
                <p style={{fontSize:"12px"}}>Select All</p>
              </MenuItem>
              {customers.map((customer) => (
                <MenuItem
                  className="mapping-menu"
                  sx={{ fontSize: "12px" }}
                  key={customer.customerId}
                  value={customer}
                >
                  <Checkbox
                    checked={isSelected(customer.customerId)}
                    size="small"
                  />
                  <p style={{fontSize:"12px"}}>
                    {customer.customerName}
                  </p>
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            className="yekkada-btn"
            disabled={!selectedDevice || selectedCustomers.length === 0}
            size="small"
            sx={{ mt: 2, textTransform: "none", fontSize: "0.70rem" }}
          >
            Map Selected Customers
          </Button>
          <div className="section-title" style={{ marginTop: "20px" }}>
            Current Mappings
          </div>
          <Table size="small" className="mapping-table">
            <TableHead>
              <TableRow>
              
                <TableCell>Customer ID</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {mappings.map((mapping) => (
                <TableRow key={mapping.id}>
                  
                  <TableCell>{mapping.customerName}({mapping.customerId})</TableCell>
                  <TableCell>
                    <IconButton
                      color="secondary"
                      onClick={() => handleDelete(mapping.id)}
                      size="small"
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Container>
      </div>
    </div>
  );
};

export default MappingComponent;
