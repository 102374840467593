import React from 'react';
export default function TermsConditions() {
    return (

        <div className="container">
            <div className="screen-layout ">
                <div className='col-central-content'>
                    <h1>Terms and Conditions</h1>

                    <h2>1. Acceptance of Terms</h2>
                    <p>
                        This is a legal agreement between Customer and Satyology Solutions Private Limited for the use of YEKKADA – GET TRACKKING!
                        is an any kind of asset/people tracking software hosted at Satyoloy Solutions Data Center. (“the Platform”), you agree to comply with
                        and be bound by these Terms and Conditions. If you do not agree with these terms, please refrain from using the Platform.
                    </p>

                    <h2>2. Use of the Platform</h2>
                    <h3>2.1. Eligibility</h3>
                    <p>
                        You must be of legal age and capable of forming a binding contract to use the Platform. If you are accessing the Platform on behalf of an organization,
                        you represent and warrant that you have the authority to bind that organization.
                    </p>
                    <h3>2.2. User Account</h3>
                    <p>
                        To use certain features of the Platform, you may be required to create an account. You are responsible for maintaining the confidentiality of your account credentials
                        and are liable for all activities that occur under your account.
                    </p>

                    <h2>3. Platform Services</h2>
                    <h3>3.1. Asset/People Tracking</h3>
                    <p>
                        The Platform offers services for tracking assets or people. Users understand that accurate tracking may depend on various factors,
                        including device capabilities, network connectivity, and environmental conditions.
                    </p>
                    <h3>3.2. Accuracy and Reliability</h3>
                    <p>
                        While we strive to provide accurate and reliable tracking services, we do not guarantee the accuracy, completeness, or reliability of the information obtained through the Platform.
                    </p>
                    <h3>3.3. Technical Support</h3>
                    <p>
                        Satyoloy Soluiton agrees to provide Customer with technical support services which include periodic distribution of bug fixes and minor enhancements as Updates.
                        Technical support e-mail inquiries are accepted at any time and will be answered during normal Satyology Solutions business hours. Satyology Solutions will attempt to respond to inquiries within 24 hours for requests from Monday to Friday and for requests received during the weekend, we will respond within 48 hours or the following Monday.
                    </p>
                    <h3>3.4. Updates</h3>
                    <p>
                        All platform general updates will be installed automatically after they are released without any extra fee.
                    </p>
                    <h2>4. User Responsibilities</h2>
                    <h3>4.1. Compliance with Laws</h3>
                    <p>
                        Users agree to comply with all applicable laws and regulations when using the Platform. This includes obtaining any necessary consents for tracking individuals.
                    </p>
                    <h3>4.2. Data Accuracy</h3>
                    <p>
                        Users are responsible for providing accurate information and maintaining the accuracy of data associated with their accounts.
                    </p>

                    <h2>5. Privacy Policy</h2>
                    <p>
                        Users are encouraged to review our Privacy Policy to understand how we collect, use, and protect their data.
                    </p>

                    <h2>6. Intellectual Property</h2>
                    <p>
                        The Platform and its content are protected by intellectual property laws. Users may not use, reproduce, or distribute any content from the Platform without permission.
                    </p>
                    <h3>The Platform License</h3>
                    <p>
                        Satyology Solutions grants to Customer a time-limited access to Hosted Software. Access is non-assignable, non-transferable, without the right to sublicense.
                    </p>
                    <h3>Documentation License</h3>
                    <p>
                        Subject to the terms and conditions of this agreement, Satyology Solutions grants to Customer a non-assignable, non-transferable license, without the right to sublicense, to use the Documentation in connection with Customer’s authorized use of the Platform.
                        Customer may not reproduce or distribute the Documentation in any manner, whether physically or electronically, without the express written permission of Satyology Solutions.
                    </p>

                    <h2>7. Limitation of Liability</h2>
                    <p>
                        To the extent permitted by law, we shall not be liable for any direct, indirect, incidental, consequential, or special damages arising from the use of the Platform or inability to use the Platform.
                    </p>

                    <h2>8. Termination</h2>
                    <p>
                        We reserve the right to terminate or suspend user accounts at our discretion, without prior notice, for violations of these terms or for any other reason.
                    </p>

                    <h2>9. Governing Law</h2>
                    <p>
                        These terms and conditions are governed by the laws of India. Any disputes arising out of or in connection with these terms shall be subject to the exclusive jurisdiction of the courts in Eluru, Andhra Pradesh.
                    </p>
                </div>
            </div>
        </div>
    )
}