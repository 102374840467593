import React, { useState, useEffect } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import * as Constants from "../utils/Constants";
import "./lockUnlock.css";
import {
  getGeolocationFromLatLng,
  formatDateToDDMMYYYY,
  getAuthToken,
  getClientIdOfUser,
} from "../utils/utility";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import FileSaver from "file-saver";
import { logoutFun } from "./HomeSideNavBar";
import { CardList } from "./CardView";
import { customStyles } from "../utils/DataTableCustomStyle";

const LockUnlockReportSearch = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [reports, setReports] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  let [assetList, setAssetList] = useState([]);

  const ExpandedComponent = ({ data }) => (
    <ul className="report-item">
      {Object.entries(data).map(([key, value]) => (
        <li key={key}>
          <p>
            <strong>{key}:</strong>
            {typeof value === "boolean" ? value.toString() : value}
          </p>
        </li>
      ))}
    </ul>
  );

  const columns = [
    {
      name: "Report ID",
      selector: (row) => row.reportSerialNumber,
      sortable: true,
      grow: 0.2,
    },
    {
      name: "Asset ID",
      selector: (row) => row.assetId,
      sortable: true,
      grow: 0.3,
    },
    {
      name: "Asset Name",
      selector: (row) => row.assetName,
      sortable: true,
      grow: 0.5,
    },
    {
      name: "Report Date-Time ",
      selector: (row) => row.reportDateTime,
      sortable: true,
      cell: (row) =>
        row.reportDateTime
          ? new Date(row.reportDateTime).toLocaleString("en-IN", {
              timeZone: "UTC",
            })
          : "N/A",
      grow: 0.6,
    },
    {
      name: "Location",
      selector: (row) => row.location,
      sortable: true,
      wrap: true,
      format: (row) => `${row.location.slice(0, 120)}`,
    },
    {
      name: "Event Source",
      selector: (row) => row.eventSource,
      sortable: true,
      wrap: true,
      format: (row) => `${row.eventSource.slice(0, 30)}`,
      grow: 0.5,
    },
    {
      name: "Unlock successful",
      selector: (row) => row.unlockSuccessful,
      sortable: true,
      cell: (row) => (row.unlockSuccessful ? "True" : "False"),
      grow: 0.5,
    },
  ];

  const handleSearch = async () => {
    setIsLoading(true);
    setError(null);

    try {
      // Input validations
      if (Object.keys(checkedItems).length === 0 && (!startDate || !endDate)) {
        const errorMessage =
          "Select one of AssetId/AssetName/Date Range (Start & End Date) or check at least one asset.";
        alert(errorMessage);
        return; // Exit the function if validation fails
      }

      axios.defaults.headers.common["Authorization"] = `${
        Constants.BEARER
      } ${getAuthToken()}`;

      // Constructing the URL with query parameters
      let apiUrl = `api/v1/lock-unlock-reports/${getClientIdOfUser()}`;

      // Check if any assetIds are checked

      // Check if any assetIds are checked
      const checkedAssetIds = Object.keys(checkedItems).filter(
        (assetId) => checkedItems[assetId]
      );

      // If there are checked assetIds, add them to the URL
      if (checkedAssetIds.length > 0) {
        const assetIdsQueryParam = checkedAssetIds.join(",");
        apiUrl += `?assetIds=${assetIdsQueryParam}`;
      }

      // If both startDate and endDate are present and not empty, add them to the URL
      if (startDate && endDate) {
        apiUrl += `${
          checkedAssetIds.length > 0 ? "&" : "?"
        }startDate=${startDate}&endDate=${endDate}`;
      }

      const response = await axios.get(Constants.BASE_URL + apiUrl);

      if (response.status === 200) {
        const responseData = response.data;

        // Call the function to update location for reports
        let updatedReports = await updateLocationForReports(responseData);

        // Fetch assets to update asset names
        const assets = await fetchData();
        updatedReports = updateAssetNameInReport(assets, updatedReports);

        // Set the updated reports in your state
        setReports(updatedReports);
      } else if (response.status === 403) {
        logoutFun();
      } else {
        console.error(response.status);
      }
    } catch (error) {
      console.error(error.message);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const updateAssetNameInReport = (assets, updatedReports) => {
    return updatedReports.map((report) => {
      const assetId = report.assetId;
      const assetName = getAssetNameByAssetUnitId(assets, assetId);
      return { ...report, assetName: assetName };
    });
  };

  const getAssetNameByAssetUnitId = (assets, targetAssetUnitId) => {
    const targetAsset = assets.find(
      (asset) => asset.assetUnitId === targetAssetUnitId
    );
    return targetAsset ? targetAsset.name : null;
  };

  async function updateLocationForReports(reports) {
    const updatedReports = await Promise.all(
      reports.map(async (report) => {
        // Check if latitude and longitude are present
        if (report.latitude !== undefined && report.longitude !== undefined) {
          // Fetch location using reverse geolocation
          const location = await getGeolocationFromLatLng(
            report.latitude,
            report.longitude
          );

          // Update the report object with the new location
          return {
            ...report,
            location: location || "Location not available", // Provide a default value if location is not available
          };
        }

        return report;
      })
    );
    return updatedReports;
  }

  const exportToExcel = () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const fileName = "lock_unlock_reports";

    const ws = XLSX.utils.json_to_sheet(reports);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    const logo = require("../assets/Yekkada_256x72.png"); // Adjust the path to your logo
    const title = `Lock-Unlock Reports  \t\t\t ${getCurrentDateTime()}`;

    doc.addImage(logo, "PNG", 10, 10, 30, 20);
    doc.setFontSize(12);
    doc.text(title, 75, 30);

    doc.autoTable({
      head: [
        [
          "Report Id",
          "Asset ID",
          "Name",
          "Date",
          "Time",
          "Location",
          "Event Source",
          "Unlock Successful",
        ],
      ],
      body: reports.map((report) => [
        report.reportSerialNumber,
        report.assetId,
        report.assetName,
        report.reportDate,
        report.reportTime,
        report.location,
        report.eventSource,
        report.unlockSuccessful ? "True" : "False",
      ]),
      startY: 50,
    });

    doc.save("lock_unlock_reports.pdf");
  };

  const getCurrentDateTime = () => {
    const currentDate = new Date().toLocaleDateString();
    const currentTime = new Date().toLocaleTimeString();
    return `${currentDate} ${currentTime}`;
  };

  useEffect(() => {
    fetchData()
      .then((data) => {
        if (Array.isArray(data)) {
          setAssetList(data);
        } else {
          console.error("Data is not an array:", data);
          // Handle the case where data is not an array
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        // Handle error if needed
      });
  }, []);

  const fetchData = async () => {
    try {
      const endPoint = `${Constants.API_V1}/${getClientIdOfUser()}/${
        Constants.EP_ASSETS_GET_ALL
      }`;
      console.log("API endpoint:", Constants.BASE_URL + endPoint);

      axios.defaults.headers.common["Authorization"] = `${
        Constants.BEARER
      } ${getAuthToken()}`;

      const response = await axios.get(`${Constants.BASE_URL}${endPoint}`);

      if (response.status === 200) {
        return response.data.responseData;
      } else if (response.status === 403) {
        logoutFun();
      } else {
        console.error(`Error: ${response.status} - ${response.statusText}`);
        // Handle other status codes if needed
        return null;
      }
    } catch (error) {
      console.error(error);
      logoutFun();
      // Handle other errors if needed
      return null;
    }
  };

  const handleClear = () => {
    setStartDate("");
    setEndDate("");
    setReports([]);
    setCheckedItems({});

    var elements = document.getElementsByClassName("input-field");

    // Iterate over the collection and update the values
    for (var i = 0; i < elements.length; i++) {
      elements[i].value = "";
    }
  };

  const [checkedItems, setCheckedItems] = useState({});

  const handleCheckboxToggle = (assetUnitId) => {
    setCheckedItems((prev) => ({
      ...prev,
      [assetUnitId]: !prev[assetUnitId],
    }));
  };

  return (
    <div className="main-child">
      {/* Display fetched reports */}
      <div className="central-section container scrollable-y">
        {reports.length <= 0 && (
          <>
            <span>
              Your reports will appear here, you can either enter Asset Id,
              Asset Name and/or Date Range.
            </span>
          </>
        )}
        {reports.length > 0 && <div className="section-title">Lock Unlock Report</div>}
        <DataTable
          title=""
          columns={columns}
          data={reports}
          pagination
          progressPending={isLoading}
          noDataComponent={error ? <div>Error loading data</div> : null}
          keyField={(row) => row.assetId}
          customStyles={customStyles}
          expandableRows
          expandableRowsComponent={ExpandedComponent}
        />
        {reports.length > 0 && (
          <div className="export-div">
            {/* Export to Excel */}
            <button className="export-button" onClick={exportToExcel}>
              Export to Excel
            </button>

            {/* Export to PDF */}
            <button className="export-button" onClick={exportToPDF}>
              Export to PDF
            </button>
          </div>
        )}
      </div>

      <div className="search-container">
        <label>
          <u>Search Filters</u>
        </label>

        <CardList
          data={assetList}
          checkedItems={checkedItems}
          setCheckedItems={setCheckedItems}
          toggleCheckbox={handleCheckboxToggle}
        />

        <div>
          <label>Start Date:</label>
          <input
            className="search-child input-field"
            type="date"
            onChange={(e) => setStartDate(formatDateToDDMMYYYY(e.target.value))}
          />
        </div>

        <div>
          <label>End Date:</label>
          <input
            type="date"
            className="search-child input-field"
            onChange={(e) => setEndDate(formatDateToDDMMYYYY(e.target.value))}
          />
        </div>

        <div>
          <button className="search-child" onClick={handleSearch}>
            Search
          </button>
          {/* Clear button */}
          <button className="search-child" onClick={handleClear}>
            Clear
          </button>
        </div>
      </div>
    </div>
  );
};

export default LockUnlockReportSearch;
