import React, { useState, useEffect, useRef } from "react";
import html2pdf from "html2pdf.js";
import {
  generateRandomNumber,
  getLoggedInUserId,
  formatDateToDDMMYYYY,
  getAuthToken,
  getClientIdOfUser,
  showToast,
} from "../../utils/utility";
import * as Constants from "../../utils/Constants";
import * as UrlConstants from "../../utils/UrlConstants";
import { TaskCardList } from "./TaskCardView";
import "./taskReport.css";
import MapLink from "../../utils/MapLink";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapLocationDot } from "@fortawesome/free-solid-svg-icons";
import ExportToExcel from "./ExportToExcel";

function TaskReport() {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [reports, setReports] = useState([]);
  const [taskCompletedList, setTaskCompletedList] = useState([]);
  const [checkedItems, setCheckedItems] = useState({});

  useEffect(() => {
    fetchTasks();
  }, [startDate, endDate]);

  const taskReportRef = useRef(null);

  const handleExportPDF = () => {

    if (reports.length === 0) {
      showToast("No data available for export.", {
        autoClose: 3000,
        type: "error",
        position: "top-center",
      });
      return;
    }


    const element = taskReportRef.current;
    const now = new Date();
    const istOffset = 330; // IST is UTC +5:30, which is 330 minutes ahead
    const istTime = new Date(now.getTime() + (istOffset * 60 * 1000));
    const formattedDate = istTime.toISOString().slice(0, 19).replace(/[:T]/g, '-');
    const fileName = `TasksReport_${formattedDate}.pdf`;

    html2pdf(element, {
      margin: [0.5, 0.5], // Adjust margins (in inches)
      filename: fileName,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    });
  };

  const fetchTasks = () => {
    let endPoint = `${Constants.API_V1}/${getClientIdOfUser()}/tasks/${
      UrlConstants.GET_ALL_TASKS_BY_STATUS
    }?taskStatus=COMPLETED`;

    if (startDate && endDate) {
      endPoint += `&startDate=${startDate}&endDate=${endDate}`;
    }

    fetchTasksFromServer(endPoint);
  };

  const fetchTasksFromServer = (endPoint) => {
    fetch(`${Constants.BASE_URL}${endPoint}`, {
      method: "GET",
      headers: {
        Authorization: `${Constants.BEARER} ${getAuthToken()}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // Sort tasks by taskId in descending order
        const sortedTasks = data.responseData.sort(
          (a, b) => b.taskId - a.taskId
        );
        setTaskCompletedList(sortedTasks);
        console.log(sortedTasks);
      })
      .catch((error) => console.error("Error fetching tasks:", error));
  };

  const handleCheckboxToggle = (taskId) => {
    setCheckedItems((prev) => ({
      ...prev,
      [taskId]: !prev[taskId],
    }));
  };

  const handleClear = () => {
    setStartDate("");
    setEndDate("");
    setReports([]);
    setCheckedItems({});
    setTaskCompletedList([]);
    fetchTasks();
  };

  const handleSearch = () => {
    if (!startDate || !endDate) {
      const errorMessage = "Select Date Range (Start & End Date).";
      alert(errorMessage);
      return;
    }
    fetchTasks();
  };

  const fetchReport = () => {
    const requestBody = {
      requestId: generateRandomNumber(),
      userId: getLoggedInUserId(),
      clientId: getClientIdOfUser(),
      requestBody: {
        taskIds: Object.keys(checkedItems).filter(
          (taskId) => checkedItems[taskId]
        ),
      },
    };

    if (Object.keys(checkedItems).length <= 0) {
      showToast("Please select at least one task for report generation.", {
        autoClose: 3000,
        type: "error",
        position: "top-center",
      });
      return;
    }

    fetch(
      `${Constants.BASE_URL}${
        Constants.API_V1
      }/${getClientIdOfUser()}/task/reports`,
      {
        method: "POST",
        headers: {
          Authorization: `${Constants.BEARER} ${getAuthToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("Report data:", data);
        setReports(data.responseData.taskDtoList);
      })
      .catch((error) => console.error("Error fetching report:", error));
  };

  const renderTaskTable = () => {
    return (
      <div>
        {reports.map((report, reportIndex) => (
          <div key={reportIndex} className="task-report">
            <div className="section-title">Report - {reportIndex + 1}</div>
            <table style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th>Task ID</th>
                  <th>Title</th>
                  <th>Description</th>
                  <th>Device ID</th>
                  <th colSpan={2}>Task Status</th>
                </tr>
              </thead>
              <tbody>{renderTaskRow(report)}</tbody>
            </table>
          </div>
        ))}
      </div>
    );
  };

  const renderTaskRow = (task) => {
    return (
      <React.Fragment key={task.taskId}>
        <tr>
          <td>{task.taskId}</td>
          <td>{task.title}</td>
          <td>{task.description}</td>
          <td>{task.deviceId}</td>
          <td colSpan={2}>{task.taskStatus}</td>
        </tr>
        <tr>
          <td>
            <b>Start Time</b>
          </td>
          <td>
            <b>End Time</b>
          </td>
          <td>
            <b>Assigned To</b>
          </td>
          <td>
            <b>Attached To</b>
          </td>
          <td>
            <b>All Service Location Visited</b>
          </td>
          <td>
            <b>Lock Tampered</b>
          </td>
        </tr>
        <tr>
          <td>
            {new Date(task.startTime).toLocaleString("en-IN", {
              timeZone: "UTC",
            })}
          </td>
          <td>
            {new Date(task.endTime).toLocaleString("en-IN", {
              timeZone: "UTC",
            })}
          </td>
          <td>{task.userAssignedTo}</td>
          <td>{task.assignedEntity || "None"}</td>
          <td>{task.allServiceLocationVisited ? "Yes" : "No"}</td>
          <td>{task.lockTampered ? "Yes" : "No"}</td>
        </tr>
        {task.customerList.map(renderCustomerRow)}
        <tr>
          <td colSpan={7} className="sub-title-header-1">
            <b>Other Lock/Unlock Events</b>
          </td>
        </tr>
        <tr>
          <td colSpan={7}>
            {task.lockUnlockEventList && task.lockUnlockEventList.length > 0 ? (
              <table style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th>Report Serial Number</th>
                    <th>Event Source</th>
                    <th>Time</th>
                    <th>Location link</th>
                  </tr>
                </thead>
                <tbody>
                  {task.lockUnlockEventList.map((event, index) => (
                    <tr key={index}>
                      <td>{event.reportSerialNumber}</td>
                      <td>{event.eventSource}</td>
                      <td>
                        {new Date(event.reportDateTime).toLocaleString(
                          "en-IN",
                          { timeZone: "UTC" }
                        )}
                      </td>
                      <td>
                        <MapLink
                          latitude={event.latitude}
                          longitude={event.longitude}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <label>-None-</label>
            )}
          </td>
        </tr>
      </React.Fragment>
    );
  };

  const renderCustomerRow = (customer, index) => {
    return (
      <tr key={customer.taskLocationId}>
        <td colSpan="7">
          <p className="title-p">
            <b>
              <FontAwesomeIcon
                icon={faMapLocationDot}
                style={{ color: "#FFF" }}
                xxl
              />{" "}
              <u> Service Location {index + 1}</u>
            </b>
          </p>
          <table style={{ width: "100%" }}>
            <tr>
              <td>
                <b>Address:</b>
              </td>
              <td>{customer.address}</td>
              <td>
                <b>Phone Number:</b>
              </td>
              <td>{customer.phoneNumber}</td>
            </tr>
            <tr>
              <td>
                <b>Customer Detail:</b>
              </td>
              <td>{customer.customerDetail}</td>
              <td>
                <b>Email ID:</b>
              </td>
              <td>{customer.emailId || "None"}</td>
            </tr>
          </table>
          {/* <br /> */}
          {customer.geoFenceAlertList.length > 0 &&
            renderGeoFenceAlertTable(customer.geoFenceAlertList)}
        </td>
      </tr>
    );
  };

  const renderGeoFenceAlertTable = (geoFenceAlertList) => {
    return (
      <div className="sub-table">
        <table style={{ width: "100%" }}>
          <tr>
            <td className="stretch-width">
              <table style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th>Check-In/Check-Out</th>
                    <th>Time</th>
                  </tr>
                </thead>
                <tbody>
                  {geoFenceAlertList.map((alert, index) => (
                    <tr key={index}>
                      <td>
                        <b>
                          {" "}
                          {alert.alertType === "GEO_FENCE_ENTRY"
                            ? "Check-In"
                            : "Check-Out"}
                        </b>
                      </td>
                      <td>
                        {new Date(alert.dateTime).toLocaleString("en-IN", {
                          timeZone: "UTC",
                        })}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </td>
            <td className="stretch-width">
              <p>Lock and Unlock Events</p>
              {geoFenceAlertList &&
                geoFenceAlertList.length > 0 &&
                geoFenceAlertList.map((alert, alertIndex) => (
                  <React.Fragment key={alertIndex}>
                    <div className="sub-table">
                      {alert.associatedLockUnlockEvents &&
                      alert.associatedLockUnlockEvents.length > 0 ? (
                        <table style={{ width: "100%" }}>
                          <tbody>
                            {alert.associatedLockUnlockEvents.map(
                              (event, eventIndex) => (
                                <React.Fragment key={eventIndex}>
                                  <tr>
                                    <td rowSpan={2}>
                                      Report Id: {event.reportSerialNumber}{" "}
                                      <br />
                                      <MapLink
                                        latitude={event.latitude}
                                        longitude={event.longitude}
                                      />
                                    </td>
                                    <td>
                                      <b>Lock/Unlock Event</b>
                                    </td>
                                    <td>{event.eventSource}</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <b>Time</b>
                                    </td>
                                    <td>
                                      {new Date(
                                        event.reportDateTime
                                      ).toLocaleString("en-IN", {
                                        timeZone: "UTC",
                                      })}
                                    </td>
                                  </tr>
                                </React.Fragment>
                              )
                            )}
                          </tbody>
                        </table>
                      ) : (
                        <label>-None-</label>
                      )}
                    </div>
                  </React.Fragment>
                ))}
            </td>
          </tr>
        </table>
      </div>
    );
  };

  return (
    <div className="main-child">
      <div className="central-section container scrollable-y">
        {reports.length <= 0 && (
          <span>
            Your reports will appear here, you can select tasks and/or Date
            Range.
          </span>
        )}
        {reports.length > 0 && (
          <div ref={taskReportRef} id="#task-report">
            <div className="section-title">Task Report</div>
            <div className="task-list">{renderTaskTable()}</div>
          </div>
        )}
      </div>
      <div className="search-container  scrollable-y">
        <label>
          <u>Completed Task List:</u>
        </label>
        {taskCompletedList.length === 0 && <p>No tasks found.</p>}
        {taskCompletedList.length !== 0 && (
          <TaskCardList
            data={taskCompletedList}
            checkedItems={checkedItems}
            setCheckedItems={setCheckedItems}
            toggleCheckbox={handleCheckboxToggle}
          />
        )}
        <span>
          <lable>
            <u>Filter tasks by date range</u>
          </lable>
        </span>
        <div>
          <label>Start Date:</label>
          <input
            className="search-child input-field"
            type="date"
            onChange={(e) => setStartDate(formatDateToDDMMYYYY(e.target.value))}
          />
        </div>
        <div>
          <label>End Date:</label>
          <input
            type="date"
            className="search-child input-field"
            onChange={(e) => setEndDate(formatDateToDDMMYYYY(e.target.value))}
          />
        </div>
        <div>
          <button className="search-child" onClick={handleSearch}>
            Tasks by Date range
          </button>
          <button className="search-child" onClick={fetchReport}>
            Fetch Report
          </button>
          <div className="button-container search-child">
            <button className="search-child" onClick={handleExportPDF}>
              Export to PDF
            </button>
            <ExportToExcel data={reports} />
          </div>
          <button className="search-child" onClick={handleClear}>
              Clear
            </button>
         
        </div>
      </div>
    </div>
  );
}

export default TaskReport;
