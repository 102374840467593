import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { customStyles } from "../../utils/DataTableCustomStyle";
import * as UrlConstants from "../../utils/UrlConstants";
import * as Constants from "../../utils/Constants";
import axios from "axios";
import { logoutFun } from "../HomeSideNavBar";
import { getAuthToken, getClientIdOfUser, showToast } from '../../utils/utility';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPenToSquare, faCircleXmark
} from "@fortawesome/free-solid-svg-icons";

import { Tooltip } from 'react-tooltip';


export default function ListUsers() {

    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);


    const columns = [
        {
            name: "User ID",
            selector: (row) => row.userId,
            sortable: true,
            grow: 0.35
        },
        {
            name: "Name",
            selector: (row) => row.fullName,
            sortable: true,
            grow: 0.7
        },
        {
            name: "Email Id",
            selector: (row) => row.email,
            sortable: true,
            grow: 0.7
        },
        {
            name: "Role",
            selector: (row) => row.userRole,
            sortable: true,
            grow: 0.5
        },
        {
            name: "Phone Number",
            selector: (row) => row.phoneNumber,
            sortable: true, wrap: true,
            grow: 0.5
        },
        {
            name: "Status",
            selector: (row) => row.userStatus,
            sortable: true, wrap: true,
            format: row => `${row.userStatus.slice(0, 30)}`,
            grow: 0.35
        },
        {
            name: "Login Id",
            selector: (row) => row.username,
            sortable: true,
            grow: 0.7
        },
        {
            name: "Manage",
            selector: (row) => row.edit,
            sortable: true,
            grow: 0.5
        }
    ];


    useEffect(() => {
        setIsLoading(true)
        fetchData().then((data) => {
            if (Array.isArray(data)) {
                setUsers(data);
            } else {
                console.error("Data is not an array:", data);
                // Handle the case where data is not an array
                setError('No Data found')
            }
            setIsLoading(false)
        }).catch((error) => {
            console.error("Error fetching data:", error);
            // Handle error if needed
            setIsLoading(false)
        });
    }, []);


    const fetchData = async () => {
        try {
            const endPoint = `${Constants.API_V1}/${getClientIdOfUser()}/users/${UrlConstants.GET_ALL_CLIENT_USERS}`;
            console.log("API endpoint:", Constants.BASE_URL + endPoint);

            axios.defaults.headers.common['Authorization'] = `${Constants.BEARER} ${getAuthToken()}`;

            const response = await axios.get(`${Constants.BASE_URL}${endPoint}`);

            if (response.status === 200) {
                const modifiedData = response.data.responseData.map(user => ({
                    ...user,
                    edit: UserManageComponent()
                }));
                return modifiedData;
            } else if (response.status === 403) {
                logoutFun()
            } else {
                console.error(`Error: ${response.status} - ${response.statusText}`);
                // Handle other status codes if needed
                return null;
            }
        } catch (error) {
            console.error(error);
            logoutFun()
            // Handle other errors if needed
            return null;
        }
    };



    return (
        <div className="main-child">
            <div className="central-section container scrollable-y">
                <div className='section-title'>
                    <span>User List</span>
                </div>
                <br/>
                <DataTable
                    title=""
                    columns={columns}
                    data={users}
                    pagination
                    responsive
                    highlightOnHover
                    striped
                    progressPending={isLoading}
                    noDataComponent={error ? <div>Error loading data</div> : null}
                    keyField={(row) => row.userId}
                    customStyles={customStyles}
                />
                <Tooltip id="my-tooltip" />
            </div>
        </div>
    )
}

function UserManageComponent(userId) {
    return <>

        <FontAwesomeIcon onClick={() => editUser(userId)} data-tooltip-id="my-tooltip" data-tooltip-content="Edit User" size="lg" icon={faPenToSquare} />
        &nbsp; &nbsp; &nbsp; &nbsp;

        <FontAwesomeIcon onClick={() => suspendUser(userId)} data-tooltip-id="my-tooltip" data-tooltip-content="Suspend User" size="lg" icon={faCircleXmark} />

    </>;
}

function editUser() {
    showToast('To be implemented', { autoClose: 1500, type: 'info', position: 'top-center' });
}

function suspendUser() {
    showToast('To be implemented', { autoClose: 1500, type: 'info', position: 'top-center' });
}