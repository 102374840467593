import React from 'react';
import * as XLSX from 'xlsx';
import { showToast } from '../../utils/utility'; // Make sure the import path is correct

const ExportToExcel = ({ data }) => {
  const handleExport = () => {
    if (!data || data.length === 0) {
      showToast('No data available for export.', {
        autoClose: 3000,
        type: 'error',
        position: 'top-center',
      });
      return;
    }

    // Prepare the workbook
    const workbook = XLSX.utils.book_new();

    // Create the first sheet for task details
    const taskSheetData = data.map(task => ({
      TaskId: task.taskId,
      ClientId: task.clientId,
      UserAssignedTo: task.userAssignedTo,
      DeviceId: task.deviceId,
      Title: task.title,
      Description: task.description,
      TaskStatus: task.taskStatus,
      StartTime: new Date(task.startTime).toLocaleString('en-IN', { timeZone: 'UTC' }),
      EndTime: new Date(task.endTime).toLocaleString('en-IN', { timeZone: 'UTC' })
    }));
    const taskSheet = XLSX.utils.json_to_sheet(taskSheetData);
    XLSX.utils.book_append_sheet(workbook, taskSheet, 'Task List');

    // Create the second sheet for customerList
    const customerSheetData = [];
    data.forEach(task => {
      task.customerList.forEach(customer => {
        customerSheetData.push({
          TaskId: task.taskId,
          TaskLocationId: customer.taskLocationId,
          Address: customer.address,
          Latitude: customer.latitude,
          Longitude: customer.longitude,
          CustomerDetail: customer.customerDetail,
          PhoneNumber: customer.phoneNumber
        });
      });
    });
    const customerSheet = XLSX.utils.json_to_sheet(customerSheetData);
    XLSX.utils.book_append_sheet(workbook, customerSheet, 'Service Locations');

    // Create the third sheet for allLockUnlockEventList
    const lockUnlockSheetData = [];
    data.forEach(task => {
      if (task.allLockUnlockEventList) {
        task.allLockUnlockEventList.forEach(event => {
          lockUnlockSheetData.push({
            TaskId: task.taskId,
            TaskLocationId: event.taskLocationId? event.taskLocationId : 'N/A',
            LockUnlockAlertId: event.reportSerialNumber,
            AssetId: event.assetId,
            ReportDate: event.reportDate,
            ReportTime: event.reportTime,
            Latitude: event.latitude,
            Longitude: event.longitude,
            Speed: event.speed,
            EventSource: event.eventSource,
            UnlockSuccessful: event.unlockSuccessful,
            RfidKeyNumber: event.rfidKeyNumber,
            PasswordCorrect: event.passwordCorrect,
            InputWrongPasswordTimes: event.inputWrongPasswordTimes
          });
        });
      }
    });
    const lockUnlockSheet = XLSX.utils.json_to_sheet(lockUnlockSheetData);
    XLSX.utils.book_append_sheet(workbook, lockUnlockSheet, 'Lock Unlock Events');

    // Create the fourth sheet for geoFenceAlertList
    const geoFenceAlertSheetData = [];
    data.forEach(task => {
      task.geoFenceAlertList.forEach(alert => {
        geoFenceAlertSheetData.push({
          TaskId: task.taskId,
          TaskLocationId:alert.taskLocationId,
          GeoFenceId: alert.geoFenceId,
          AlertType: alert.alertType,
          AssetUnitId: alert.assetUnitId,
          DateTime: new Date(alert.dateTime).toLocaleString('en-IN', { timeZone: 'UTC' })
        });
      });
    });
    const geoFenceAlertSheet = XLSX.utils.json_to_sheet(geoFenceAlertSheetData);
    XLSX.utils.book_append_sheet(workbook, geoFenceAlertSheet, 'Geo Fence Alerts');

    // Generate current date and time in IST for the file name
    const now = new Date();
    const istOffset = 330; // IST is UTC +5:30, which is 330 minutes ahead
    const istTime = new Date(now.getTime() + (istOffset * 60 * 1000));
    const formattedDate = istTime.toISOString().slice(0, 19).replace(/[:T]/g, '-');
    const fileName = `TasksReport_${formattedDate}.xlsx`;

    // Save the workbook to a file
    XLSX.writeFile(workbook, fileName);
  };

  return (
    <button onClick={handleExport}>Export to Excel</button>
  );
};

export default ExportToExcel;
