import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faMicrochip, faUser, faGear,
    faUsers, faArrowRightFromBracket,
    faListCheck, faDashboard, faFileInvoice
} from "@fortawesome/free-solid-svg-icons";
import { NavLink, useLocation } from "react-router-dom";
import { ApiDataContext } from './ApiDataContext'
import * as Constants from "../utils/Constants";
import {getAuthToken} from "../utils/utility";

export const logoutFun = async () => {
    try {
        const token = getAuthToken()
        localStorage.removeItem('isAuthenticated')
        axios.defaults.headers.common['Authorization'] = `${Constants.BEARER} ${token}`;
        const response = await axios.get(Constants.BASE_URL + 'logout');
        if (response.status === 200) {
            const responseUrl = response.data;
            window.location.href = responseUrl;
        } else {
            localStorage.removeItem(Constants.LOGGED_IN_USER)
            localStorage.removeItem(Constants.IS_AUTHENTICATED)
            window.location.href = "/" + Constants.LOGIN;
        }
    } catch (error) {
        console.log(error)
        localStorage.removeItem(Constants.LOGGED_IN_USER)
        localStorage.removeItem(Constants.IS_AUTHENTICATED)
        window.location.href = "/" + Constants.LOGIN;
    }
}

export default function HomeSideNavBar() {
    const [selectedItem, setSelectedItem] = useState('');
    const { fetchData } = useContext(ApiDataContext);
    const location = useLocation();

    const handleItemClick = (item) => {
        setSelectedItem(item);
    }

    function prepareAndFetchAllDevices() {
        let user = JSON.parse(localStorage.getItem(Constants.LOGGED_IN_USER))
        let endPoint = Constants.API_V1 + '/' + user.clientId + '/' + Constants.EP_ASSETS_GET_ALL
        fetchData(endPoint)
    }

    useEffect(() => {
        // Initialize the selected item based on the current route
        const currentPath = location.pathname;

        if (currentPath.includes('/home')) {
            setSelectedItem('Dashboard');
        } else if (currentPath.includes('/reports')) {
            setSelectedItem(Constants.REPORTS);
        } else if (currentPath.includes('/profile')) {
            setSelectedItem(Constants.PROFILE);
        } else if (currentPath.includes('/assets')) {
            setSelectedItem(Constants.ASSETS);
        } else if (currentPath.includes('/users')) {
            setSelectedItem(Constants.USERS);
        } else if (currentPath.includes('/tasks')) {
            setSelectedItem(Constants.TASK);
        } else if (currentPath.includes('/settings')) {
            setSelectedItem(Constants.SETTINGS);
        } else if (currentPath.includes('/logout')) {
            setSelectedItem('Logout');
        }
    }, [location.pathname]);

    useEffect(() => {
        // Check if the selected item is "Dashboard" before fetching data
        if (selectedItem === 'Dashboard') {
            prepareAndFetchAllDevices();
        }
    }, [selectedItem]);

    return (
        <div className="grid-div col-vertical-menu">
            <li className={selectedItem === 'Dashboard' ? Constants.SELECTED : ''}
                onClick={() => { handleItemClick('Dashboard'); prepareAndFetchAllDevices(); }}>
                <NavLink to="/home">
                    <FontAwesomeIcon className="vert-menu-icon" icon={faDashboard} />
                </NavLink>
                <span>Dashboard</span>
            </li>
            
            <li className={selectedItem === Constants.ASSETS ? Constants.SELECTED : ''}
                onClick={() => handleItemClick(Constants.ASSETS)}>
                <NavLink to="/assets">
                    <FontAwesomeIcon className="vert-menu-icon" icon={faMicrochip} />
                </NavLink>
                <span>Assets</span>
            </li>
            <li className={selectedItem === Constants.USERS ? Constants.SELECTED : ''}
                onClick={() => handleItemClick(Constants.USERS)}>
                <NavLink to="/users">
                    <FontAwesomeIcon className="vert-menu-icon" icon={faUsers} />
                </NavLink>
                <span>Users</span>
            </li>
            <li className={selectedItem === Constants.TASK ? Constants.SELECTED : ''}
                onClick={() => handleItemClick(Constants.TASK)}>
                <NavLink to="/tasks">
                    <FontAwesomeIcon className="vert-menu-icon" icon={faListCheck} />
                </NavLink>
                <span>Task</span>
            </li>
            <li className={selectedItem === Constants.REPORTS ? Constants.SELECTED : ''}
                onClick={() => handleItemClick(Constants.REPORTS)}>
                <NavLink to="/reports">
                    <FontAwesomeIcon className="vert-menu-icon" icon={faFileInvoice} />
                </NavLink>
                <span>Reports</span>
            </li>
            <li className={selectedItem === Constants.SETTINGS ? Constants.SELECTED : ''}
                onClick={() => handleItemClick(Constants.SETTINGS)}>
                <NavLink to="/settings">
                    <FontAwesomeIcon className="vert-menu-icon" icon={faGear} />
                </NavLink>
                <span>Settings</span>
            </li>
            <li className={selectedItem === Constants.PROFILE ? Constants.SELECTED : ''}
                onClick={() => handleItemClick(Constants.PROFILE)}>
                <NavLink to="/profile">
                    <FontAwesomeIcon className="vert-menu-icon" icon={faUser} />
                </NavLink>
                <span>Profile</span>
            </li>
            <li className={selectedItem === 'Logout' ? Constants.SELECTED : ''}
                onClick={logoutFun}>
                <FontAwesomeIcon className="vert-menu-icon" icon={faArrowRightFromBracket} />
                <span>Logout</span>
            </li>
        </div>
    )
}
