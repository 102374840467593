import React, { useState } from "react";
import HomeSideNavBar from "../HomeSideNavBar";
import Header from "../Header";
import "../home.css"
import ApiDataProvider from "../ApiDataContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faMicrochip } from "@fortawesome/free-solid-svg-icons";
import ListAssets from "./ListAssets";
import RegisterAsset from "./RegisterAsset";


function Assets() {

    const LIST_ASSETS = 'AssetList'
    const REGISTER_ASSET = 'RegisterAsset'

    const [selectedComponent, setSelectedComponent] = useState(LIST_ASSETS);


    const renderSelectedComponent = () => {
        switch (selectedComponent) {
            case LIST_ASSETS:
                return <ListAssets />;
            case REGISTER_ASSET:
                return <RegisterAsset />;
            // case 'C':
            //     return <ComponentC />;
            default:
                return null;
        }
    };

    return (
        <ApiDataProvider>
            <div className="container">
                <div className="screen-layout">
                    <Header />
                    <HomeSideNavBar />
                    <div className="main-section-div" >
                        <div className="report-nav-bar">
                            <button
                                className={selectedComponent === LIST_ASSETS ? 'selected' : ''}
                                onClick={() => setSelectedComponent(LIST_ASSETS)}>
                                <FontAwesomeIcon style={{ color: '#363168' }} icon={faMicrochip} /> &nbsp;Asset List
                            </button>
                            <button
                                className={selectedComponent === REGISTER_ASSET ? 'selected' : ''}
                                onClick={() => setSelectedComponent(REGISTER_ASSET)}>
                                <FontAwesomeIcon style={{ color: '#363168' }} icon={faFile} /> &nbsp;Register Asset
                            </button>
                        </div>
                        {renderSelectedComponent()}
                    </div>
                </div>
            </div>
        </ApiDataProvider>
    )
}

export default Assets