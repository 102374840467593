import React from "react";
import "./home.css"
import HomeSideNavBar from "./HomeSideNavBar";
import HomeCentralSection from "./HomeCentralSection";
import Header from "./Header";
import ApiDataProvider from "./ApiDataContext";


export default function Home() {
  return (
    <ApiDataProvider>
      <div className="container">
        <div className="screen-layout">
          <Header />
          <HomeSideNavBar />
          <HomeCentralSection />
        </div>
      </div>
    </ApiDataProvider>
  );
};
