import React, { useState } from "react";
import HomeSideNavBar from "../HomeSideNavBar";
import Header from "../Header";
import "../home.css";
import ApiDataProvider from "../ApiDataContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faMicrochip } from "@fortawesome/free-solid-svg-icons";
import CreateTask from "./CreateTask";
import TaskList from "./TaskList";
import * as Constants from "../../utils/Constants";
import { getAuthToken } from '../../utils/utility';

function Tasks() {
    const TASK_LIST = 'TaskList';
    const CREATE_TASK = 'CreateTask';

    const [selectedComponent, setSelectedComponent] = useState(TASK_LIST);
    const [taskToCopy, setTaskToCopy] = useState(null);

    const handleCopyTask = (task) => {
        fetchCustomers(task)       
    };

    const fetchCustomers = (task) => {
        const endPoint = `${Constants.BASE_URL}${Constants.API_V1}/tasks/${task.taskId}/customers`;
    
        fetch(endPoint, {
            method: 'GET',
            headers: {
                'Authorization': `${Constants.BEARER} ${getAuthToken()}`
            }
        })
        .then(response => response.json())
        .then(data => {
            // Remove taskLocationId from each customer object
            const updatedCustomerList = data.map(customer => {
                const { taskLocationId, ...rest } = customer;
                return rest;
            });
    
            // Update CustomerList in task
            const updatedTask = { ...task, customerList: updatedCustomerList };
    
            // Set task to copy in state variable
            setTaskToCopy(updatedTask);
            setTimeout(() => {
                console.log('After 1 seconds');
                setSelectedComponent(CREATE_TASK);
            }, 300);
        })
        .catch(error => console.error('Error fetching customers:', error));
    };
 
    


    const renderSelectedComponent = () => {
        switch (selectedComponent) {
            case TASK_LIST:
                return <TaskList onCopyTask={handleCopyTask} />;
            case CREATE_TASK:
                return <CreateTask taskToCopy={taskToCopy} setTaskToCopy={setTaskToCopy} />;
            default:
                return null;
        }
    };

    return (
        <ApiDataProvider>
            <div className="container">
                <div className="screen-layout">
                    <Header />
                    <HomeSideNavBar />
                    <div className="main-section-div">
                        <div className="report-nav-bar">
                            <button
                                className={selectedComponent === TASK_LIST ? 'selected' : ''}
                                onClick={() => setSelectedComponent(TASK_LIST)}>
                                <FontAwesomeIcon style={{ color: '#363168' }} icon={faMicrochip} /> &nbsp;Task List
                            </button>
                            <button
                                className={selectedComponent === CREATE_TASK ? 'selected' : ''}
                                onClick={() => setSelectedComponent(CREATE_TASK)}>
                                <FontAwesomeIcon style={{ color: '#363168' }} icon={faFile} /> &nbsp;Create Task
                            </button>
                        </div>
                        {renderSelectedComponent()}
                    </div>
                </div>
            </div>
        </ApiDataProvider>
    );
}

export default Tasks;
