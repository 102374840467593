import React, { useEffect, useContext, useState } from "react";
import { ApiDataContext } from "./ApiDataContext";
import ReusableTable from "../utils/ReusableTable";
import DynamicTable from "../utils/DynamicTable";
import * as Constants from "../utils/Constants";
import * as UrlContants from "../utils/UrlConstants";
import SockJS from "sockjs-client";
import axios from "axios";
import Stomp from "stompjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHashtag,
  faLocationCrosshairs,
  faSignal,
  faClock,
  faSatellite,
  faRotate,
  faUnlock,
  faSpinner,
  faMapLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import {
  getCurrentTimeMillis,
  parseJsonIfNotEmpty,
  getGeolocationFromLatLng,
  getDataFromBackend,
  getClientIdOfUser,
  getAuthToken,
  showToast,
} from "../utils/utility";
import NotificationIcon from "./NotificationIcon";
import { logoutFun } from "./HomeSideNavBar";
import AlertPopupDialog from "./AlertPopupDialog";
import LoadingBanner from "../utils/LoadingBanner";
import MapLink from "../utils/MapLink";

export default function HomeCentralSection() {
  let { data } = useContext(ApiDataContext);
  let [apiResponse, setApiResponse] = useState([]);
  let [deviceLat, setDeviceLat] = useState("");
  let [deviceLong, setDeviceLong] = useState("");

  let [assetName, setAssetName] = useState("");
  let [selectedDevice, setSelectedDevice] = useState("");

  let [lockStatus, setLockStatus] = useState("");
  let [assetDetails, setAssetDetails] = useState({});
  let [dateTime, setDateTime] = useState("");
  let [noOfSatellite, setNoOfSatellite] = useState("");
  let [backCoverStatus, setBackCoverStatus] = useState("");
  let [gsmSignalQuality, setGsmSignalQuality] = useState("");

  //Alert Details
  let [lowBatteryAlert, setLowBatteryAlert] = useState("");
  let [backCoverOpenAlert, setBackCoverOpenAlert] = useState("");
  let [vibrationAlert, setVibrationAlert] = useState("");
  let [lockRopeTamperAlert, setLockRopeTamperAlert] = useState("");
  let [unlockingAlert, setUnlockingAlert] = useState("");
  let [wrongPasswordAlert, setWrongPasswordAlert] = useState("");
  let [unauthorizedRfidAlert, setUnauthorizedRfidAlert] = useState("");

  let [deviceTimers, setDeviceTimers] = useState([]);
  let [isLocationFetched, setLocationFetched] = useState(false);
  let [isVisible, setIsVisible] = useState(false);
  let [actionMessage, setActionMessage] = useState("");
  let [deviceStatus, setDeviceStatus] = useState("");
  let [alertState, setAlertState] = useState(new Map());

  let [showPopup, setShowPopup] = useState(false);
  let [popupData, setPopupData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  async function getAlerts(assetUnitId) {
    const endPoint = `${Constants.API_V1}/${
      UrlContants.LOCATION_ALERT_REPORTS
    }/${getClientIdOfUser()}/${UrlContants.GET_ALERTS}?assetId=${assetUnitId}`;
    console.log("API endpoint:", Constants.BASE_URL + endPoint);
    const response = await getDataFromBackend(endPoint);

    if (response.status === 200) {
      const alerts = response.data.responseData;
      console.log(alerts);
      setPopupData(alerts);
    } else if (response.status === 403) {
      logoutFun();
    } else {
      console.error(`Error: ${response.status} - ${response.statusText}`);
      setShowPopup(false);
    }

    setShowPopup(!showPopup);
  }

  const togglePopup = () => {
    setShowPopup(!showPopup);
    if (!showPopup) {
      setPopupData([]);
    }
  };

  useEffect(() => {
    // Simulate loading delay
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 300); // Change delay time as needed

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    if (data != null) {
      console.log(data.responseData);
      if (null != data.responseData) {
        const transformedData = data.responseData.map((item) => {
          let additionalInfoFields = {};
          let additionalInfoJson = null;
          // Check if additionalInfo exists and parse it as JSON
          if (item.additionalInfo) {
            additionalInfoJson = JSON.parse(item.additionalInfo);
            try {
              additionalInfoFields = {
                dateTimeF1:
                  additionalInfoJson.date + " " + additionalInfoJson.istTime,
                deviceStatusF2: Constants.LOADING_ARROW,
                batteryStatusF3: additionalInfoJson.batteryPercentage + "%",
                lockStatusF4: additionalInfoJson.lockStatus
                  ? Constants.LOCKED
                  : Constants.OPEN,
                assetMovementStatusF5:
                  item.speed > 0
                    ? "Moving " + additionalInfoJson.speed + "kmph"
                    : "Idle",
              };
            } catch (error) {
              console.error("Error parsing additionalInfo:", error);
            }
          }

          // Extract fields or set default values
          const {
            dateTimeF1 = "NA",
            deviceStatusF2 = Constants.LOADING_ARROW,
            batteryStatusF3 = "NA",
            lockStatusF4 = "NA",
            assetMovementStatusF5 = "NA",
          } = additionalInfoFields;

          // Create the transformed object
          return {
            name: item.name,
            assetUnitId: item.assetUnitId,
            assetType: item.assetType,
            dateTimeF1,
            deviceStatusF2,
            batteryStatusF3,
            assetMovementStatusF5,
            location: "",
            lockStatusF4,
            alertStatus: "-",
          };
        });

        const tempAssetDetails = data.responseData.map((item) => ({
          assetUnitId: item.assetUnitId,
          details: parseJsonIfNotEmpty(item.additionalInfo),
        }));

        console.log(transformedData);
        setApiResponse(transformedData);

        console.log(tempAssetDetails);
        setAssetDetails(tempAssetDetails);

        let initialTimers = data.responseData.map((item) => ({
          assetUnitId: item.assetUnitId,
          lastLoadtime: getCurrentTimeMillis(),
        }));
        console.log(initialTimers);
        setDeviceTimers(initialTimers);
        setTimeout(fetchData, 2800);
      }
    }
  }, [data]);

  async function fetchData() {
    try {
      const endPoint = `${Constants.API_V1}/${
        UrlContants.LOCATION_ALERT_REPORTS
      }/${getClientIdOfUser()}/${UrlContants.GET_ALERTS_COUNT}`;
      console.log("API endpoint:", Constants.BASE_URL + endPoint);
      axios.defaults.headers.common["Authorization"] = `${
        Constants.BEARER
      } ${getAuthToken()}`;
      const response = await getDataFromBackend(endPoint);

      if (response.status === 200) {
        const alertCountList = response.data.responseData;
        const map = new Map(alertState);
        alertCountList.forEach((item) => {
          map.set(item.assetUnitId, item.alertCount);
          updateObjectFieldsById(item.assetUnitId, {
            alertStatus:
              item.alertCount > 0 ? (
                <NotificationIcon onClick={() => getAlerts(item.assetUnitId)} />
              ) : (
                "-"
              ),
          });
        });
        setAlertState(map);
      } else if (response.status === 403) {
        logoutFun();
      } else {
        console.error(`Error: ${response.status} - ${response.statusText}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const fetchAndUpdateLocation = async () => {
    if (apiResponse.length > 0 && assetDetails.length > 0) {
      const updatedApiResponse = await Promise.all(
        apiResponse.map(async (item) => {
          const correspondingAssetDetails = assetDetails.find(
            (details) => details.assetUnitId === item.assetUnitId
          );

          if (correspondingAssetDetails && correspondingAssetDetails.details) {
            const { latitude, longitude } = correspondingAssetDetails.details;
            const location = await getGeolocationFromLatLng(
              latitude,
              longitude
            );

            return {
              ...item,
              location: location || "Location not available",
            };
          }

          return item;
        })
      );
      setApiResponse(updatedApiResponse);
    }
  };

  useEffect(() => {
    // Check if both apiResponse and assetDetails are available
    if (
      apiResponse.length > 0 &&
      assetDetails.length > 0 &&
      !isLocationFetched
    ) {
      // Make the Google Geolocation API call only once on page load
      fetchAndUpdateLocation();
      setLocationFetched(true);
    }
  }, [apiResponse, assetDetails, isLocationFetched]); // Include isLocationFetched in the dependency array

  const LOCATION = (
    <>
      <span>Location</span>{" "}
      <FontAwesomeIcon
        onClick={fetchAndUpdateLocation}
        className="hoverPointer"
        icon={faRotate}
        size="lg"
        style={{ color: "#FFF" }}
      />
    </>
  );
  const DEVICES_ROW_HEADER = [
    "Asset Name",
    "Asset Id",
    "Asset Type",
    "Date & Time",
    "Device Status",
    "Battery Status",
    "Asset movement Status",
    LOCATION,
    "Lock Status",
    "Alert",
  ];

  useEffect(() => {
    const socket = new SockJS(Constants.BASE_URL + Constants.GPS_WEBSOCKET);
    const stompClient = Stomp.over(socket);

    const updateDeviceTimers = (updatedAssetUnitId) => {
      const currentMillis = getCurrentTimeMillis();

      // Create a copy of the deviceTimers array to avoid direct mutation
      const updatedDeviceTimers = [...deviceTimers];

      // Find the index of the deviceTimer object with the matching assetUnitId
      const index = updatedDeviceTimers.findIndex(
        (deviceTimer) => deviceTimer.assetUnitId === updatedAssetUnitId
      );

      if (index !== -1) {
        // Update the lastLoadtime of the found deviceTimer object
        updatedDeviceTimers[index].lastLoadtime = currentMillis;

        // Set the updated deviceTimers state using a setter function
        setDeviceTimers((prevState) => updatedDeviceTimers);
      } else {
        console.error(
          `Device with assetUnitId ${updatedAssetUnitId} not found in deviceTimers`
        );
      }
    };

    stompClient.connect({}, (frame) => {
      console.log("Connected: " + frame);

      // Subscribe to the /topic/gpsData topic.
      stompClient.subscribe("/topic/gpsData", (message) => {
        console.log(message.body);
        try {
          const messageData = JSON.parse(message.body);
          if (messageData && typeof messageData === "object") {
            if (selectedDevice === messageData.deviceId) {
              if (messageData.command === "P43") {
                updatelockStatus(messageData.unlockStatus !== 1);
                setActionMessage(
                  messageData.unlockStatus !== 1
                    ? Constants.FAIL
                    : Constants.SUCCESS
                );
              } else {
                setDeviceLat(messageData.latitude);
                setDeviceLong(messageData.longitude);
                updatelockStatus(messageData.lockStatus);
                updateBackCoverStatus(messageData.backCoverStatus);
                setDateTime(messageData.date + " " + messageData.istTime);
                setNoOfSatellite(messageData.numberOfSatellite);
                setGsmSignalQuality(messageData.gsmSignalQuality);
                setDeviceStatus(Constants.DEVICE_ONLINE);

                //Alert Details
                setLowBatteryAlert(messageData.lowBatteryAlert);
                setBackCoverOpenAlert(messageData.backCoverOpenAlert);
                setUnlockingAlert(messageData.unlockingAlert);
                setWrongPasswordAlert(messageData.wrongPasswordAlert);
                setVibrationAlert(messageData.vibrationAlert);
                setLockRopeTamperAlert(messageData.lockRopeTamperAlert);
                setUnauthorizedRfidAlert(messageData.unauthorizedRfidAlert);
              }
            }

            if (messageData.command !== "P43") {
              console.log(alertState);
              if (messageData.alert) {
                const map = new Map(alertState);
                map.set(
                  messageData.deviceId,
                  alertState.get(messageData.deviceId) + 1
                );
                setAlertState(map);
              }
              updateObjectFieldsById(messageData.deviceId, {
                dateTimeF1: messageData.date + " " + messageData.istTime,
                deviceStatusF2: Constants.DEVICE_ONLINE,
                batteryStatusF3: messageData.batteryPercentage + "%",
                lockStatusF4: messageData.lockStatus
                  ? Constants.LOCKED
                  : Constants.OPEN,
                assetMovementStatusF5:
                  messageData.speed > 0
                    ? "Moving " + messageData.speed + "kmph"
                    : "Idle",
              });
              if (messageData.alert) {
                updateObjectFieldsById(messageData.deviceId, {
                  alertStatus: (
                    <NotificationIcon
                      onClick={() => getAlerts(messageData.deviceId)}
                    />
                  ),
                });
              }

              updateLatitudeAndLongitude(
                messageData.deviceId,
                messageData.latitude,
                messageData.longitude
              );
            } else {
              updateObjectFieldsById(messageData.deviceId, {
                lockStatusF4:
                  messageData.unlockStatus !== 1
                    ? Constants.LOCKED
                    : Constants.OPEN,
              });

              if (messageData.unlockStatus === 1) {
                updateObjectFieldsById(messageData.deviceId, {
                  alertStatus: (
                    <NotificationIcon
                      onClick={() => getAlerts(messageData.deviceId)}
                    />
                  ),
                });
              }

              updateDetailsFieldById(messageData.deviceId, {
                lockStatus:
                  messageData.unlockStatus !== 1
                    ? Constants.LOCK_CLOSE
                    : Constants.LOCK_OPEN,
              });
            }

            updateDeviceTimers(messageData.deviceId);
          }
        } catch (error) {
          console.error("Invalid JSON: ", error);
        }
      });
    });

    // Cleanup function
    return () => {
      if (stompClient && stompClient.connected) {
        stompClient.disconnect();
      }
    };
  }, [selectedDevice, deviceTimers]);

  const showConfirmation = (message) => {
    const isConfirmed = window.confirm("Are you sure you want to proceed?");
    if (isConfirmed) {
      sendCommandToDevice(message);
    } else {
      // Logic when user clicks Cancel (optional)
      console.log("Cancel clicked");
    }
  };

  function sendCommandToDevice(message) {
    console.log(
      "WebSocket Url: " + Constants.BASE_URL + Constants.GPS_WEBSOCKET
    );
    const socket = new SockJS(Constants.BASE_URL + Constants.GPS_WEBSOCKET);
    const independentStompClient = Stomp.over(socket);
    setIsVisible(true);
    setActionMessage(
      <>
        <span id="actionMessage">
          <FontAwesomeIcon icon={faSpinner} spin />
        </span>
      </>
    );
    setTimeout(() => {
      console.log("After 15 seconds");
      setActionMessage("");
    }, 15000);
    independentStompClient.connect({}, (frame) => {
      console.log("Connected for sending message: " + frame);

      try {
        // Customize the destination and message format based on your server's requirements
        independentStompClient.send(
          "/app/send-command",
          {},
          JSON.stringify({
            command: message,
            deviceId: selectedDevice,
          })
        );

        // Disconnect the independent connection after sending the message
        if (independentStompClient && independentStompClient.connected) {
          independentStompClient.disconnect();
        }
      } catch (e) {
        console.log(e);
      }
    });
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Your block of code here
      console.log("Running block of code every 1.5 minutes");
      console.log(deviceTimers);

      for (const deviceIndex in deviceTimers) {
        const device = deviceTimers[deviceIndex];
        const timeDifferenceMillis =
          getCurrentTimeMillis() - device.lastLoadtime;

        if (timeDifferenceMillis > Constants.OFFLINE_TIMEOUT) {
          console.log(
            `Device ${device.assetUnitId} has not been updated for more than 2.5 minutes.`
          );
          updateObjectFieldsById(device.assetUnitId, {
            deviceStatusF2: Constants.DEVICE_OFFLINE,
            assetMovementStatusF5: Constants.NO_INFO,
          });
          setDeviceStatus(Constants.DEVICE_OFFLINE);
        }
      }
    }, 30000); // 1.5 minutes in milliseconds

    return () => {
      clearInterval(intervalId); // Clear the interval when the component unmounts
    };
  }, [deviceTimers]);

  const updateObjectFieldsById = (assetUnitId, updatedFields) => {
    setApiResponse((prevApiResponse) => {
      const indexToUpdate = prevApiResponse.findIndex(
        (obj) => obj.assetUnitId === assetUnitId
      );

      if (indexToUpdate !== -1) {
        const newArray = [...prevApiResponse];
        newArray[indexToUpdate] = {
          ...newArray[indexToUpdate],
          ...updatedFields,
        };
        return newArray;
      }

      // If the id is not found, return the previous state unchanged
      return prevApiResponse;
    });
  };

  // Function to update latitude and longitude in assetDetails based on assetUnitId
  const updateLatitudeAndLongitude = (assetUnitId, latitude, longitude) => {
    setAssetDetails((prevDetails) => {
      const updatedDetails = prevDetails.map((detail) => {
        if (detail.assetUnitId === assetUnitId) {
          return {
            ...detail,
            details: {
              ...detail.details,
              latitude,
              longitude,
            },
          };
        }
        return detail;
      });
      return updatedDetails;
    });
  };

  const updateDetailsFieldById = (assetUnitId, updatedFields) => {
    setAssetDetails((prevDetails) => {
      const indexToUpdate = prevDetails.findIndex(
        (detail) => detail.assetUnitId === assetUnitId
      );

      if (indexToUpdate !== -1) {
        const newArray = [...prevDetails];
        newArray[indexToUpdate] = {
          ...newArray[indexToUpdate],
          details: {
            ...newArray[indexToUpdate].details,
            ...updatedFields,
          },
        };
        return newArray;
      }

      // If the id is not found, return the previous state unchanged
      return prevDetails;
    });
  };

  const handleRowClick = async (rowData) => {
    console.log("Row clicked:", rowData); // Trigger some event with the rowData
    setSelectedDevice(rowData.assetUnitId);
    setAssetName(
      <>
        {rowData.name}(<b>{rowData.assetUnitId}</b>)
      </>
    );
    console.log(selectedDevice);
    resetDeviceDetails();
    setDeviceStatus(rowData.deviceStatusF2);
    findAssetAndShowDetails(rowData.assetUnitId);
  };

  function resetDeviceDetails() {
    setDeviceLat("");
    setDeviceLong("");
    setLockStatus("");
    setNoOfSatellite("");
    setBackCoverStatus("");
    setDateTime("");
    setGsmSignalQuality("");

    setLowBatteryAlert("");
    setBackCoverOpenAlert("");
    setUnlockingAlert("");
    setWrongPasswordAlert("");
    setVibrationAlert("");
    setLockRopeTamperAlert("");
    setUnauthorizedRfidAlert("");
    setActionMessage("");
  }

  const getFieldValueById = (assetUnitId, field) => {
    const asset = apiResponse.find((obj) => obj.assetUnitId === assetUnitId);
    if (asset) {
      return asset[field];
    }
    // If the id is not found or the field is not present, return a default value or null
    return null; // You can customize this based on your requirements
  };

  const findAssetAndShowDetails = (assetUnitId) => {
    const details = assetDetails.find(
      (item) => item.assetUnitId === assetUnitId
    );
    if (details.details) {
      console.log("Found Object:", details);
      let detail = details.details;
      updatelockStatus(
        getFieldValueById(assetUnitId, "lockStatusF4") !== Constants.OPEN
      );
      setDeviceStatus(getFieldValueById(assetUnitId, "deviceStatusF2"));
      updateBackCoverStatus(detail.backCoverStatus);
      setDeviceLat(detail.latitude);
      setDeviceLong(detail.longitude);
      setNoOfSatellite(detail.numberOfSatellite);
      setGsmSignalQuality(detail.gsmSignalQuality);
      setDateTime(detail.date + " " + detail.istTime);

      //Alert Details
      setLowBatteryAlert(detail.lowBatteryAlert);
      setBackCoverOpenAlert(detail.backCoverOpenAlert);
      setUnlockingAlert(detail.unlockingAlert);
      setWrongPasswordAlert(detail.wrongPasswordAlert);
      setVibrationAlert(detail.vibrationAlert);
      setLockRopeTamperAlert(detail.lockRopeTamperAlert);
      setUnauthorizedRfidAlert(detail.unauthorizedRfidAlert);
    } else {
      console.log("Object not found for input:", assetUnitId);
    }
  };

  const details_headers = [<u>Device Detail</u>, ""];
  const details_tabledata = [
    {
      c1: (
        <>
          <FontAwesomeIcon icon={faHashtag} /> <span>{assetName}</span>
        </>
      ),
      c2: (
        <>
          <FontAwesomeIcon icon={faClock} />{" "}
          <span>
            Date Time
            <br />
            {dateTime}
          </span>
        </>
      ),
    },
    {
      c1: (
        <>
          <FontAwesomeIcon icon={faSatellite} />{" "}
          <span>Number of captured satellites:</span>
        </>
      ),
      c2: <>{noOfSatellite}</>,
    },
    {
      c1: (
        <>
          <FontAwesomeIcon icon={faSignal} /> <span>GSM Signal Quality:</span>
        </>
      ),
      c2: <>{gsmSignalQuality}</>,
    },
    {
      c1: (
        <>
          <FontAwesomeIcon icon={faLocationCrosshairs} /> <span>Latitude:</span>
        </>
      ),
      c2: deviceLat,
    },
    {
      c1: (
        <>
          <FontAwesomeIcon icon={faLocationCrosshairs} />{" "}
          <span>Longitude:</span>
        </>
      ),
      c2: deviceLong,
    },
    {
      c1: (
        <>
          <FontAwesomeIcon icon={faMapLocationDot} /> <span>Marker:</span>
        </>
      ),
      c2: <MapLink latitude={deviceLat} longitude={deviceLong} />,
    },
    {
      c1: "BackCover Status:",
      c2: backCoverStatus,
    },
    {
      c1: "Lock Rope Status:",
      c2: lockStatus,
    },
  ];

  const alert_detail_headers = [<u>Alert Detail</u>, ""];
  const alert_detail_data = [
    {
      c1: "Low battery alert",
      c2: lowBatteryAlert ? Constants.ALERT_WARNING : Constants.NORMAL,
    },
    {
      c1: "Back cover Open alert",
      c2: backCoverOpenAlert ? Constants.ALERT_WARNING : Constants.NORMAL,
    },
    {
      c1: "Vibration alert",
      c2: vibrationAlert ? Constants.ALERT_WARNING : Constants.NORMAL,
    },
    {
      c1: "Lock rope tamper alert",
      c2: lockRopeTamperAlert ? Constants.ALERT_WARNING : Constants.NORMAL,
    },
    {
      c1: "Unlocking alert",
      c2: unlockingAlert ? Constants.ALERT_WARNING : Constants.NORMAL,
    },
    {
      c1: "Wrong password alert",
      c2: wrongPasswordAlert ? Constants.ALERT_WARNING : Constants.NORMAL,
    },
    {
      c1: "Swiping unauthorized RFID key alert",
      c2: unauthorizedRfidAlert ? Constants.ALERT_WARNING : Constants.NORMAL,
    },
  ];

  function updatelockStatus(deviceLocked) {
    if (!deviceLocked) {
      setLockStatus(Constants.LOCK_OPEN);
    } else {
      setLockStatus(Constants.LOCK_CLOSE);
    }
  }

  function updateBackCoverStatus(backCoverClosed) {
    if (backCoverClosed) {
      setBackCoverStatus(Constants.BACK_COVER_CLOSE);
    } else {
      setBackCoverStatus(Constants.BACK_COVER_OPEN);
    }
  }

  let action_header = [<u>Control Actions</u>, ""];
  let action_data = [
    {
      c1: (
        <>
          <button
            className="yekkada-button"
            disabled={
              selectedDevice === "" ||
              lockStatus === Constants.LOCK_OPEN ||
              deviceStatus !== Constants.DEVICE_ONLINE
            }
            onClick={() => showConfirmation("(P43,XXXXXX)")}
          >
            <FontAwesomeIcon size="xl" icon={faUnlock} /> Unlock Device
          </button>
        </>
      ),
      c2: isVisible && actionMessage,
    },
  ];

  const detailsDisplay = {
    display: selectedDevice !== "" ? "block" : "none",
  };

  const handleClearNotification = () => {
    if (popupData) {
      markAlertsAsRead(popupData.map((item) => item.reportId));
    }
    togglePopup(!showPopup);
  };

  const markAlertsAsRead = async (reportIds) => {
    try {
      const endPoint = `${Constants.BASE_URL}${Constants.API_V1}/${
        UrlContants.LOCATION_ALERT_REPORTS
      }/${getClientIdOfUser()}/${UrlContants.MARK_ALERTS_AS_READ}`;
      axios.defaults.headers.common["Authorization"] = `${
        Constants.BEARER
      } ${getAuthToken()}`;
      const response = await axios.post(endPoint, reportIds);
      console.log(response.data); // Handle success response
      if (response.status === 200) {
        showToast("Alerts cleared!", {
          autoClose: 3000,
          type: "success",
          position: "top-center",
        });
        updateObjectFieldsById(popupData[0].deviceId, { alertStatus: "-" });
      }
    } catch (error) {
      console.error(error); // Handle error
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingBanner />
      ) : (
        <>
          <div className="grid-div col-central-content central-section container scrollable-y">
            <div className="section-title">Device List</div>

            <ReusableTable
              id="device-list-table"
              tabledata={apiResponse}
              setTableData={setApiResponse} // Make sure to use the correct state update function
              headers={DEVICES_ROW_HEADER} // Your headers
              onRowClick={handleRowClick}
            />
          </div>
          <div className="grid-div col-right-details" style={detailsDisplay}>
            <DynamicTable
              tabledata={details_tabledata}
              headers={details_headers}
            />
            <DynamicTable
              tabledata={alert_detail_data}
              headers={alert_detail_headers}
            />

            {JSON.parse(localStorage.getItem(Constants.LOGGED_IN_USER))
              ?.userRole === Constants.CLIENT_ADMIN ? (
              <DynamicTable tabledata={action_data} headers={action_header} />
            ) : null}
          </div>

          <AlertPopupDialog
            showPopup={showPopup}
            togglePopup={togglePopup}
            popupData={popupData}
            clearNotifications={handleClearNotification}
          />
        </>
      )}
    </>
  );
}
