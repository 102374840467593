import React, { useState, useEffect } from "react";
import AddressSearch from "./AddressSearch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleInfo,
  faCircleXmark,
  faFileArrowUp,
  faPlus,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import * as UrlConstants from "../../utils/UrlConstants";
import * as Constants from "../../utils/Constants";
import {
  getAuthToken,
  getClientIdOfUser,
  showToast,
  generateRandomNumber,
  getLoggedInUserId,
} from "../../utils/utility";
import { Tooltip } from "react-tooltip";

const CreateTask = ({ taskToCopy, setTaskToCopy }) => {
  const [formData, setFormData] = useState({
    clientId: "",
    userAssignedTo: "",
    deviceId: "",
    title: "",
    description: "",
    taskStatus: "ASSIGNED",
    startTime: "",
    endTime: "",
    assignedEntity: "",
    customerList: [],
  });

  const [userList, setUserList] = useState([]);
  const [deviceList, setDeviceList] = useState([]);

  useEffect(() => {
    // Fetch user list from the server
    fetchUserList();

    // Fetch device list from the server
    fetchDeviceList();

    // If taskToCopy is provided and has customerList, set the form data accordingly
    if (taskToCopy && taskToCopy.customerList) {
      setFormData({
        ...formData,
        userAssignedTo: "",
        deviceId: "",
        taskStatus: "ASSIGNED",
        title: taskToCopy.title,
        description: taskToCopy.description,
        assignedEntity: taskToCopy.assignedEntity,
        customerList: [...taskToCopy.customerList], // Ensure deep copy
      });
    } else {
      // If taskToCopy is null or has no customerList, set the form data to default values
      setFormData({
        clientId: "",
        userAssignedTo: "",
        deviceId: "",
        title: "",
        description: "",
        taskStatus: "ASSIGNED",
        startTime: "",
        endTime: "",
        assignedEntity: "",
        customerList: [],
      });
    }
  }, [taskToCopy]);

  const fetchUserList = () => {
    const endPoint = `${Constants.API_V1}/${getClientIdOfUser()}/users/${
      UrlConstants.GET_ALL_ACTIVE_EMPLOYEES
    }`;

    // Make a fetch request to fetch the user list from the server
    fetch(`${Constants.BASE_URL}${endPoint}`, {
      method: "GET",
      headers: { Authorization: `${Constants.BEARER} ${getAuthToken()}` },
    })
      .then((response) => response.json())
      .then((data) => {
        // Update the user list state
        setUserList(data.responseData);
      })
      .catch((error) => console.error("Error fetching user list:", error));
  };

  const fetchDeviceList = () => {
    const endPoint = `${Constants.API_V1}/${getClientIdOfUser()}/assets/${
      UrlConstants.GET_ALL_AVAILABLE_ASSETS
    }`;

    // Make a fetch request to fetch the device list from the server
    fetch(`${Constants.BASE_URL}${endPoint}`, {
      method: "GET",
      headers: { Authorization: `${Constants.BEARER} ${getAuthToken()}` },
    })
      .then((response) => response.json())
      .then((data) => {
        // Update the device list state
        setDeviceList(data.responseData);
      })
      .catch((error) => console.error("Error fetching device list:", error));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePlaceSelected = (latitude, longitude, address, index) => {
    const newCustomerList = [...formData.customerList];
    newCustomerList[index] = {
      ...newCustomerList[index],
      address,
      latitude,
      longitude,
    };
    setFormData({ ...formData, customerList: newCustomerList });
  };

  const MAX_CUSTOMERS = 10; // Define the maximum number of customers allowed

  const handleAddCustomer = () => {
    if (formData.customerList.length >= MAX_CUSTOMERS) {
      showToast(
        `Maximum ${MAX_CUSTOMERS} service locations allowed per task!!!`,
        { autoClose: 3000, type: "error", position: "top-center" }
      );
      return;
    }

    const newCustomerList = [
      ...formData.customerList,
      { address: "", latitude: null, longitude: null },
    ];
    setFormData({ ...formData, customerList: newCustomerList });
  };

  const handleRemoveCustomer = (index) => {
    const newCustomerList = [...formData.customerList];
    newCustomerList.splice(index, 1);
    setFormData({ ...formData, customerList: newCustomerList });
  };

  const handleCustomerInputChange = (index, field, value) => {
    const newCustomerList = [...formData.customerList];
    newCustomerList[index][field] = value;
    setFormData({ ...formData, customerList: newCustomerList });
  };

  const validateLatitude = (latitude) => {
    const regex = /^-?[0-9]{1,2}\.[0-9]{0,15}$/; // Regex for valid latitude range (-90 to 90) with optional decimal places
    return regex.test(latitude);
  };

  const validateLongitude = (longitude) => {
    const regex = /^-?[0-9]{1,3}\.[0-9]{0,15}$/; // Regex for valid longitude range (-180 to 180) with optional decimal places
    return regex.test(longitude);
  };

  const handleCreateTask = () => {
    // Perform validation
    if (!formData.deviceId || !formData.userAssignedTo || !formData.title) {
      showToast("Please fill in all required fields.", {
        autoClose: 3000,
        type: "error",
        position: "top-center",
      });
      return;
    }

    if (formData.customerList.length === 0) {
      showToast("Please add atleast one customer details.", {
        autoClose: 3000,
        type: "error",
        position: "top-center",
      });
      return;
    }
    for (let i = 0; i < formData.customerList.length; i++) {
      const customer = formData.customerList[i];
      if (
        !customer.latitude ||
        !customer.longitude ||
        !customer.customerDetail ||
        !customer.phoneNumber
      ) {
        showToast("Please provide address for all customers.", {
          autoClose: 3000,
          type: "error",
          position: "top-center",
        });
        return;
      }
      if (
        !validateLatitude(customer.latitude) ||
        !validateLongitude(customer.longitude)
      ) {
        showToast(
          `Invalid latitude or longitude for Service location ${
            i + 1
          }. Please enter valid values.`,
          { autoClose: 3000, type: "error", position: "top-center" }
        );
        return;
      }
    }

    // If validation passes, proceed with task creation
    const requestBody = {
      requestBody: {
        clientId: formData.clientId,
        userAssignedTo: formData.userAssignedTo,
        deviceId: formData.deviceId,
        title: formData.title,
        description: formData.description,
        taskStatus: formData.taskStatus,
        startTime: formData.startTime,
        endTime: formData.endTime,
        assignedEntity: formData.assignedEntity,
        customerList: formData.customerList,
      },
      requestId: generateRandomNumber().toString(),
      clientId: getClientIdOfUser(),
      userId: getLoggedInUserId(),
    };

    fetchTaskCreation(requestBody); // Function to send the request to create the task
  };

  const fetchTaskCreation = (requestBody) => {
    const endPoint = `${Constants.API_V1}/${getClientIdOfUser()}/tasks`;
    fetch(`${Constants.BASE_URL}${endPoint}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${Constants.BEARER} ${getAuthToken()}`,
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Handle success response
        // Show success message or perform any other action
        showToast("Task created successfully.", {
          autoClose: 3000,
          type: "success",
          position: "top-center",
        });
        // Reset form data after successful task creation
        setFormData({
          clientId: "",
          userAssignedTo: "",
          deviceId: "",
          title: "",
          description: "",
          taskStatus: "ASSIGNED",
          startTime: "",
          endTime: "",
          assignedEntity: "",
          customerList: [],
        });
        setTaskToCopy(null);
        fetchDeviceList(); //Refreshing device list post successful tesk creation
      })
      .catch((error) => {
        console.error("Error creating task:", error);
        // Show error message or perform any other action
        showToast("Failed to create task. Please try again.", {
          autoClose: 3000,
          type: "error",
          position: "top-center",
        });
      });
  };

  function clearCopiedTask() {
    setTaskToCopy(null);
  }

  return (
    <div className="main-child">
      <div className="central-section container scrollable-y">
        <div className="form-row">
          <div className="form-column">
            <div className="section-title">
              <span>Task Detail</span>
              {taskToCopy && (
                <span id="clearCopy" onClick={clearCopiedTask}>
                  <FontAwesomeIcon
                    style={{ color: "#FF683A" }}
                    icon={faTrashCan}
                    size="xl"
                  /> &nbsp;
                  Clear Task
                </span>
              )}
            </div>
            <div>
              <select
                id="userAssignedTo"
                name="userAssignedTo"
                style={{ width: "15%" }}
                value={formData.userAssignedTo}
                onChange={handleInputChange}
              >
                <option value="">Select User *</option>
                {userList.map((user) => (
                  <option key={user.userId} value={user.userId}>
                    {user.fullName}
                  </option>
                ))}
              </select>

              <select
                id="deviceId"
                name="deviceId"
                value={formData.deviceId}
                onChange={handleInputChange}
              >
                <option value="">Select Device *</option>

                {deviceList.map((device) => (
                  <option key={device.assetUnitId} value={device.assetUnitId}>
                    {device.name} ({device.assetUnitId})
                  </option>
                ))}
              </select>

              <input
                type="text"
                placeholder="Task Title *"
                value={formData.title}
                name="title"
                onChange={handleInputChange} // <-- Pass the event object to handleInputChange
              />

              <input
                type="text"
                style={{ width: "30%" }}
                placeholder="Task Description"
                value={formData.description}
                name="description"
                onChange={handleInputChange} // <-- Pass the event object to handleInputChange
              />

              <input
                type="text"
                name="assignedEntity"
                placeholder="Assigned entity"
                value={formData.assignedEntity}
                onChange={handleInputChange}
              />
            </div>
          </div>

          {formData.customerList.map((customer, index) => (
            <div key={index} className="form-column">
              <div className="section-title">
                <span>Service Location {index + 1}:</span>
              </div>
              <div>
                <AddressSearch
                  onPlaceSelected={(lat, lng, address) =>
                    handlePlaceSelected(lat, lng, address, index)
                  }
                  latitude={customer.latitude}
                  longitude={customer.longitude}
                  address={customer.address}
                />

                <input
                  type="text"
                  placeholder="Customer Detail *"
                  value={customer.customerDetail || ""}
                  onChange={(e) =>
                    handleCustomerInputChange(
                      index,
                      "customerDetail",
                      e.target.value
                    )
                  }
                />
                <input
                  type="text"
                  placeholder="Phone Number *"
                  value={customer.phoneNumber || ""}
                  onChange={(e) =>
                    handleCustomerInputChange(
                      index,
                      "phoneNumber",
                      e.target.value
                    )
                  }
                />
                <input
                  type="text"
                  placeholder="Email"
                  value={customer.emailId || ""}
                  onChange={(e) =>
                    handleCustomerInputChange(index, "emailId", e.target.value)
                  }
                />
                <span
                  type="button"
                  className="remove-asset-btn icon-button top-right"
                  onClick={() => handleRemoveCustomer(index)}
                >
                  <FontAwesomeIcon
                    style={{ color: "#363168" }}
                    icon={faCircleXmark}
                  />
                </span>
                <p>{customer.address}</p>
              </div>
            </div>
          ))}

          <div className="register-btn-div">
            <button
              className="yekkada-btn"
              type="button"
              onClick={handleAddCustomer}
            >
              <FontAwesomeIcon style={{ color: "#FFF" }} icon={faPlus} />
              &nbsp; Add Service location
            </button>

            <button
              className="yekkada-btn"
              type="button"
              onClick={handleCreateTask}
            >
              <FontAwesomeIcon style={{ color: "#FFF" }} icon={faFileArrowUp} />
              &nbsp; Create Task
            </button>
            <FontAwesomeIcon
              style={{ color: "#363168" }}
              icon={faCircleInfo}
              size="xl"
              data-tooltip-id="my-tooltip"
              data-tooltip-content="Fields marked with * are mandatory"
            />
          </div>
        </div>
      </div>
      <Tooltip id="my-tooltip" />
    </div>
  );
};

export default CreateTask;
