import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { getGeolocationFromLatLng } from '../utils/utility';

const AlertPopupDialog = ({ showPopup, togglePopup, popupData, clearNotifications }) => {
    const [locationData, setLocationData] = useState([]);

    useEffect(() => {
        if (popupData) {
            const fetchLocationData = async () => {
                const data = await Promise.all(popupData.map(async (item) => {
                    const location = await getGeolocationFromLatLng(item.latitude, item.longitude);
                    return { ...item, location };
                }));
                setLocationData(data);
            };

            fetchLocationData();
        }
    }, [popupData]);

    function parseAlertData(alertObj) {
        let alertType = ''
        alertType = !alertObj.lockStatus ? alertType + 'Lock Open, ' : alertType
        alertType = !alertObj.backCoverStatus || alertObj.backCoverOpenAlert ? alertType + 'Back Cover Open, ' : alertType
        alertType = alertObj.lowBatteryAlert ? alertType + 'Low Battery, ' : alertType
        alertType = alertObj.lockRopeTamperAlert ? alertType + 'Lock Rope Tampred, ' : alertType
        alertType = alertObj.vibrationAlert ? alertType + "Vibration Alert, " : alertType
        alertType = alertObj.unauthorizedRfidAlert ? alertType + 'Unauthorized RFID.' : alertType
        return alertType;
    }

    return (
        <>
            {showPopup && (
                <div>
                    <div className="popup-overlay"></div>
                    <div className="popup-dialog">
                        <div className="popup-content">
                            {locationData.map((item, index) => {
                                const alertType = parseAlertData(item).trim();
                                if (!alertType || alertType === ',') {
                                    // If alertType is empty or contains only spaces and commas, skip rendering this record
                                    return null;
                                }
    
                                return (
                                    <div className='notification-record' key={index}>
                                        <p>
                                            <b>Id:</b> {item.reportId}
                                            &nbsp;&nbsp;&nbsp;&nbsp; {/* Insert four non-breaking spaces for tab */}
                                            <b>Date:</b> {item.date}
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <b>Time:</b> {item.istTime}
                                        </p>
                                        <p><b>Location:</b> {item.location}</p>
                                        <p><b>Alert Type:</b> {alertType}</p>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="popup-buttons">
                            <button onClick={clearNotifications}>Clear Notifications</button>
                        </div>
                        <div className="close-button" onClick={togglePopup}>
                            <FontAwesomeIcon icon={faTimes} />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default AlertPopupDialog;
