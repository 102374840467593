import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const LoadingBanner = () => {
    return (
      <div className="loading-banner">
        <FontAwesomeIcon icon={faSpinner} size='2xl' spin />
        <p> &nbsp;Loading...</p>
      </div>
    );
  };
  

export default LoadingBanner;