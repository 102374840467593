import React, { useState, useEffect } from 'react';
import * as Constants from "../../utils/Constants";
import { getAuthToken, getClientIdOfUser } from '../../utils/utility';
import MapLink from '../../utils/MapLink';

const TaskDetailsPanel = ({ data }) => {
    const [customers, setCustomers] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchCustomers(data.data.taskId);
    }, [data.data.taskId]);

    const fetchCustomers = (taskId) => {
        const endPoint = `${Constants.BASE_URL}${Constants.API_V1}/tasks/${taskId}/customers`;

        fetch(endPoint, {
            method: 'GET',
            headers: {
                'Authorization': `${Constants.BEARER} ${getAuthToken()}`
            }
        })
            .then(response => response.json())
            .then(data => {
                setCustomers(data);
                setLoading(false);
            })
            .catch(error => console.error('Error fetching customers:', error));
    };


   

    return (
        <div className='report-item'>
            <h3>Customers for Task ID: {data.data.taskId}</h3>
            <p><b>Description:</b> {data.data.description || "None"}</p>
            {loading ? (
                <p>Loading customers...</p>
            ) : (
                <ol style={{ listStyleType: 'none', padding: 0 }}>
                    {customers.map(customer => (
                        <li key={customer.taskLocationId}>
                            <span><strong>Customer Detail:</strong> {customer.customerDetail}</span>
                            <span><strong>Address:</strong> {customer.address}</span>
                            <span><strong>Phone Number:</strong> {customer.phoneNumber}</span>
                            <span><strong>Email:</strong> {customer.emailId}</span>
                            <span><strong>Location Coordinates:</strong> <MapLink latitude={customer.latitude} longitude={customer.longitude} /></span>
                            {/* Add additional fields here as needed */}
                        </li>
                    ))}
                </ol>
            )}
        </div>
    );
};

export default TaskDetailsPanel;
