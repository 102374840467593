import React, { useState } from "react";
import HomeSideNavBar from "./HomeSideNavBar";
import Header from "./Header";
import "./home.css";
import ApiDataProvider from "./ApiDataContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTags, faUpload, faUsers } from "@fortawesome/free-solid-svg-icons";
import CustomerList from "./Customer/CustomerList";
import CustomerDetailsUpload from "./Customer/CustomerDetailsUpload";
import MappingComponent from "./Customer/MappingComponent";
function Settings() {
  const UPLOAD_CUSTOMER = "UploadCustomer";
  const CUSTOMER_LIST = "CustomerList";
  const MAPPING = "mapping";

  const [selectedComponent, setSelectedComponent] = useState(CUSTOMER_LIST);

  const renderSelectedComponent = () => {
    switch (selectedComponent) {
      case UPLOAD_CUSTOMER:
        return <CustomerDetailsUpload />;
      case CUSTOMER_LIST:
        return <CustomerList />;
      case MAPPING:
        return <MappingComponent />;
      default:
        return null;
    }
  };

  return (
    <ApiDataProvider>
      <div className="container">
        <div className="screen-layout">
          <Header />
          <HomeSideNavBar />
          <div className="main-section-div">
            <div className="report-nav-bar">
              <button
                className={
                  selectedComponent === CUSTOMER_LIST ? "selected" : ""
                }
                onClick={() => setSelectedComponent(CUSTOMER_LIST)}
              >
                <FontAwesomeIcon style={{ color: "#363168" }} icon={faUsers} />{" "}
                &nbsp;Customer List
              </button>
              <button
                className={
                  selectedComponent === UPLOAD_CUSTOMER ? "selected" : ""
                }
                onClick={() => setSelectedComponent(UPLOAD_CUSTOMER)}
              >
                <FontAwesomeIcon style={{ color: "#363168" }} icon={faUpload} />{" "}
                &nbsp;Upload Customer List
              </button>
              <button
                className={selectedComponent === MAPPING ? "selected" : ""}
                onClick={() => setSelectedComponent(MAPPING)}
              >
                <FontAwesomeIcon style={{ color: "#363168" }} icon={faTags} />{" "}
                &nbsp;Mapping
              </button>
            </div>
            {renderSelectedComponent()}
          </div>
        </div>
      </div>
    </ApiDataProvider>
  );
}

export default Settings;
