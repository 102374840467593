import React from "react";
import * as XLSX from "xlsx";

const ExportTrackingReport = ({ data }) => {
  const exportDataToExcel = () => {
    const workbook = XLSX.utils.book_new();

    console.log(data);

    // Prepare Customers sheet data
    const customersData = data.geofenceEvents.map((report) => ({
      CustomerId: report.customerId,
      CustomerName: report.customerName,
      CustomerAddress: report.customerAddress,
      Email: report.email,
      PhoneNumber: report.phoneNumber,
      Latitude: report.latitude,
      Longitude: report.longitude,
    }));
    const customersSheet = XLSX.utils.json_to_sheet(customersData);
    XLSX.utils.book_append_sheet(workbook, customersSheet, "Customers");

    // Prepare GeoFenceEvents sheet data
    const geoFenceEventsData = data.geofenceEvents.flatMap((report) =>
      report.geofenceEvents.map((event) => ({
        AssetId: event.assetId,
        CustomerId: event.customerId,
        EventType: event.eventType,
        DateTime: new Date(event.timestamp).toLocaleString(),
        Latitude: event.latitude,
        Longitude: event.longitude,
      }))
    );
    const geoFenceEventsSheet = XLSX.utils.json_to_sheet(geoFenceEventsData);
    XLSX.utils.book_append_sheet(
      workbook,
      geoFenceEventsSheet,
      "GeoFenceEvents"
    );

    // Prepare LockUnlockReports sheet data
    const lockUnlockReportsData = data.geofenceEvents.flatMap((report) =>
      report.lockUnlockEvents.map((event) => ({
        ReportSerialNumber: event.reportSerialNumber,
        AssetId: event.assetId,
        CustomerId: report.customerId,
        ReportDateTime: new Date(event.reportDateTime).toLocaleString(),
        Latitude: event.latitude,
        Longitude: event.longitude,
        Speed: event.speed,
        EventSource: event.eventSource,
        UnlockSuccessful: event.unlockSuccessful ? "Yes" : "No",
        PasswordCorrect: event.passwordCorrect ? "Yes" : "No",
        InputWrongPasswordTimes: event.inputWrongPasswordTimes,
      }))
    );

    const lockUnlockReportsSheet = XLSX.utils.json_to_sheet(
      lockUnlockReportsData
    );
    XLSX.utils.book_append_sheet(
      workbook,
      lockUnlockReportsSheet,
      "LockUnlockReports"
    );

    // Prepare LockUnlockReports sheet data
    const UnassociatedlockUnlockReportsData =
      data.unassociatedLockUnlockReports.map((event) => ({
        ReportSerialNumber: event.reportSerialNumber,
        AssetId: event.assetId,
        CustomerId: "-NA-",
        ReportDateTime: new Date(event.reportDateTime).toLocaleString(),
        Latitude: event.latitude,
        Longitude: event.longitude,
        Speed: event.speed,
        EventSource: event.eventSource,
        UnlockSuccessful: event.unlockSuccessful ? "Yes" : "No",
        PasswordCorrect: event.passwordCorrect ? "Yes" : "No",
        InputWrongPasswordTimes: event.inputWrongPasswordTimes,
      }));

    const unassociatedlockUnlockReportsSheet = XLSX.utils.json_to_sheet(
      UnassociatedlockUnlockReportsData
    );
    XLSX.utils.book_append_sheet(
      workbook,
      unassociatedlockUnlockReportsSheet,
      "UnassociatedLockUnlockReports"
    );

    const now = new Date();
    const istOffset = 330; // IST is UTC +5:30, which is 330 minutes ahead
    const istTime = new Date(now.getTime() + (istOffset * 60 * 1000));
    const formattedDate = istTime.toISOString().slice(0, 19).replace(/[:T]/g, '-');
    const fileName = `ReportData_${formattedDate}.xlsx`;
    // Generate Excel file
    XLSX.writeFile(workbook, fileName);
  };

  return <button onClick={exportDataToExcel}>Export to Excel</button>;
};

export default ExportTrackingReport;
